import React, { useEffect } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { toast } from 'react-toastify';
import requestHandler from '../utilities/requestHandler';

const FlutterWaveGateway = (props) => {

    const config = {
        public_key: 'FLWPUBK_TEST-3818e669a7a3d251be14366da90c7855-X',
        tx_ref: Date.now(),
        amount: props.amount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: props.email,
          phone_number: "09187654321",
          name: props.name,
        }, 
        customizations: {
          title: 'The Main CEOs',
          description: 'Online payment',
          logo: 'https://api.themainceos.com/storage/app/uploads/images/CeNu6gpRmKz4T7J8WIK86FniwKfXTEW4Oi1BU3Hh.jpg',
        },
      };

      //const handleFlutterPayment = window.alert('Not available at the moment');
      const handleFlutterPayment = useFlutterwave(config);

      const purchaseProduct = async () =>{
        //setLoading(true)
        
        let curUrl = 'product/purchase/online'
        if(props.productType == "Downloadable"){
          curUrl = 'product/purchase/online'
        }
        else if(props.productType == "Link"){
          curUrl = 'product/purchase/online'
        }
        else if(props.productType == "Fund Wallet"){
          curUrl = 'product/purchase/online'
        }
        else if(props.productType === 'coupon'){
          curUrl = 'vendor/coupon/purchase/online'
        }
        
        let x = await requestHandler.post(curUrl, {product_id: props.id}, false, true);
        if(props.productType === 'coupon'){
          x = await requestHandler.post(curUrl, {email: props.email, amount: props.amount}, false, false);
        }
        if(x != null && x.status === 1){
          if(props.productType === 'coupon'){
            toast.success(x.message)
            alert(x.message)
          }
          else toast.success(x.message.message)
            //alert(x.message.message)
            //setPopUp(true)
            //await sleep(5000);
            //download file
            if(props.productType == "Downloadable"){
              window.location.href=x.message.link
            }
            else if(props.productType == "Link"){
              window.location.href=x.message.link
            }
            else if(props.productType == "Fund Wallet"){
              window.location.href='/'
            }
            
        }
        else{
            toast.error(x.message)
        }
        //setLoading(false)
      }

      useEffect(()=>{
         
      },[]);

      return <button type='button' className="btn btn-success"
        onClick={() => {
            handleFlutterPayment({
                callback: (response) => {
                  console.log(response);
                  //alert(response.status)
                  if(response.status === "successful"){
                    purchaseProduct();
                   // return true;
                    //redirect if redirectUrl is not null
                     if(props.redirectUrl != null){
                        window.location.replace(props.redirectUrl)
                    }
                  }
                  closePaymentModal();
                },
                onClose: () => {},
              });
        }}
      >
        Pay With Card
      </button> 

}
export default  FlutterWaveGateway