import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { ToastContainer, toast } from "react-toastify"
import requestHandler from "../../utilities/requestHandler"
import { Link } from "react-router-dom"
import Loading from "../../utilities/Loading"

const ResetPassword = () => {

    const [pageTile, setPageTitle] = useState("Reset Password")
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [code, setCode] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)

    const loginAction = async () => {
      setLoading(true)
      let Data = {email: email, password: password}
      let x = await requestHandler.post('auth/login', Data, false, false)
      if(x != null && x.status === 1){
          //let y = x.message;
          toast.success(x.message)
          //set login token
          localStorage.setItem('access_token', x.access_token)
          localStorage.setItem('account_type', x.account_type)
          window.location.href = "/"
      }
      else if(x != null && x.status === 0){
          toast.error(x.message)
      }
      else{
          toast.error('Something went wrong')
      }
      setLoading(false)
  }

    const validateEmail = async () => {
        setLoading(true)
        if(email != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('auth/password/reset', {email: email}, false, false)
            if(x != null && x.status === 1){
                //let y = x.message;
                setResetPassword(true)
                toast.success(x.message)
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else
            {
                toast.error('Something went wrong')
            }
        }
        setLoading(false)
    }

    const resetAction = async () => {
        setLoading(true)
        if(email != null){
        //setData({email: email, password: password})
        let x = await requestHandler.post('auth/password/reset/confirm', {password: password, password2: password2, code: code}, false, false)
            if(x != null && x.status === 1)
            {
                toast.success(x.message)
                loginAction()
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else
            {
                toast.error('Something went wrong')
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    return(
        <>
        <Header />
        <main>
        <div class="container">
          <div class="row">
        <div class="col">

        <div className="row" style={{display: resetPassword? `none` : `flex`}}>
        <div className="d-flex justify-content-between mt-5 row">
            <div className="col-md-6">
                <section className="scroll-section" id="login">
                  <form className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="mb-3 filled">
                        <i data-cs-icon="email"></i>
                        <input value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Email"/>
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                      <Link to="/auth/login" className="me-3">Log In</Link>
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={validateEmail} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Reset Password</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                  </form>
                </section>
                </div>
                </div>
                </div>

<div className="row" style={{display: resetPassword? 'flex' : 'none'}}>
                <div className="d-flex justify-content-around mt-5 row">
            <div className="col-md-6">
                <section className="scroll-section" id="login">
                  <h2 className="small-title">Reset Password</h2>
                  <form className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="mb-3 filled">
                        <i data-cs-icon="laptop"></i>
                        <input value={code} onChange={e => setCode(e.target.value)} className="form-control" placeholder="OTP"/>
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input value={password} onChange={e => setPassword(e.target.value)} className="form-control" type="password" placeholder="New Password" />
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input value={password2} onChange={e => setPassword2(e.target.value)} className="form-control" type="password" placeholder="Confirm Password" />
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                      <Link to="/auth/login" className="me-3">Log In</Link>
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={resetAction} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Confirm Reset</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                  </form>
                </section>
                </div>
                </div>
                </div>

        </div>
        </div>
        </div>
        </main>
        <Footer/>
        <ToastContainer />
        </>  
    )
}

export default ResetPassword;