import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import moneyFormat from "../../utilities/moneyFormat"
import number_format from "../../utilities/number_format"
import { toast } from "react-toastify"
import NumberToNaira from "../vtu/user/constants/numberToNaira"

const UserOverview = () => {
    const [pageTile, setPageTitle] = useState("Activities Overview")
    const [contentsShared, setContentsShared] = useState(0)
    const [shared, setShared] = useState(0)
    const [viewed, setViewed] = useState(0)
    const [allViews, setAllViews] = useState(0)
    const [username, setUsername] = useState(null)
    const [country, setCountry] = useState(null)
    const [package_, setPackage] = useState(null)
    const [currentBalance, setCurrentBalance] = useState(0)
    const [pointsUsed, setPointsUsed] = useState(0)
    const [withdrawn, setWithdrawn] = useState(0)
    const [referrals, setReferrals] = useState(0)
    const [referralEarnings, setReferralEarnings] = useState(0)
    const [withdrawnReferralEarnings, setWithdrawnReferralEarnings] = useState(0)
    const [isLoading, setLoading] = useState(false)

    const [activitiesBal, setActivitiesBal] = useState(true)
    const [mainBalance, setMainBalance] = useState(0)
    const [mainBal, setMainBal] = useState(true)
    const [cashBackBalance, setCashBackBalance] = useState(0)
    const [cashBackBal, setCashBackBal] = useState(true)
    
    const url = "https://themainceos.com";
    async function copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        toast.success('Link copied')
      } catch (e) {
        toast.error('Error copying')
      }
    }

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login/redirect"
      }
    }

    const userProfile = async () => {
      let x = await requestHandler.get('user/profile', true);
      if(x != null && x.status === 1){
          let y = x.message[0];
          setCountry(y.country)
          setUsername(y.username)
          setPackage(y.package)
      }
    }

    const userStats = async () => {
      setLoading(true)
        let x = await requestHandler.get('user/stats', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            //setUserData(y)
            setShared(y.shares)
            setViewed(y.clicks)
            setAllViews(y.total_clicks)
            setContentsShared(x.shares)
            setReferrals(x.referrals)
            setCurrentBalance(y.main_wallet)
            setPointsUsed(y.points_withdrawn)
            setReferralEarnings(y.referral_commission)
            setWithdrawnReferralEarnings(y.commission_withdrawn)
            setWithdrawn(y.withdrawn)
            setCashBackBalance(y.cashback_balance)
            setMainBalance(y.agent_wallet)
        }
        setLoading(false)
      }
    
    useEffect(() => {
      checkIfLoggedIn()
      userProfile()
        document.title = pageTile
        userStats()
    },[pageTile])

    return(
        <div className="col">
          <h1 class="small-title">Package - {package_}</h1>
        {/*<h2 class="small-title">Overview</h2>*/}
        <h2 class="nav-link  px-0 border-bottom border-separator-light">
                    <i className="fa-regular fa-copy me-2" onClick={()=>copyToClipboard(url+'/auth/'+username+'/register')} data-cs-size="17"></i>
                      <span class="align-middle"> {url}/auth/{username}/register </span>
                    </h2>

        {isLoading? <Loading/> : ''}
                <div class="mb-5">
                  
                  <div class="row g-2">

                  <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card bg-info text-white hover-border-primary">
                      <div class="card-body">
                        {
                          mainBal? 
                <div>
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Wallet Balance</span>
                            <i className="fa-regular fa-wallet"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="text-large">{NumberToNaira(mainBalance)}</div>
                           </div>

                          :

                          <div>
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Cash Back</span>
                            <i className="fa-regular fa-wallet"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="text-large">{NumberToNaira(cashBackBalance)}</div>
                        </div>

                        }
                         <div className="d-flex justify-content-between">
                          <button onClick={()=>setMainBal(true)} className={mainBal? "btn btn-info" : "btn btn-primary"}>Main Wallet</button>
                          <button onClick={()=>setMainBal(false)} className={!mainBal? "btn btn-info" : "btn btn-primary"}>Cash Back</button>
                          </div>
                          </div>
                      </div>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card bg-success text-white hover-border-primary">
                      <div class="card-body">
                        {
                          activitiesBal? 
                <div>
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Activities Points</span>
                            <i className="fa-regular fa-money-check-dollar"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="text-large">{number_format(currentBalance)}</div>
                           </div>
                          :

                          <div>
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Used Points</span>
                            <i className="fa-regular fa-money-check-dollar"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="text-large">{number_format(pointsUsed)}</div>
                        </div>

                        }
                         <div className="d-flex justify-content-between">
                          <button onClick={()=>setActivitiesBal(true)} className={activitiesBal? "btn btn-success" : "btn btn-primary"}>Available</button>
                          <button onClick={()=>setActivitiesBal(false)} className={!activitiesBal? "btn btn-success" : "btn btn-primary"}>Used</button>
                          </div>
                          </div>
                      </div>
                    </div>



                    {/*<div class="col-12 col-sm-6 col-lg-6">
                      <div class="card bg-warning text-white hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Points Used</span>
                            <i className="fa-regular fa-money-check-dollar"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="cta-1">{number_format(pointsUsed)}</div>
                        </div>
                      </div>
                    </div>*/}


                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card bg-info text-white hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Affiliate Earnings</span>
                            <i className="fa-regular fa-money-bills"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="cta-1">{moneyFormat(referralEarnings, country)}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card bg-secondary text-white hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Withdrawn</span>
                            <i className="fa-regular fa-money-bills"></i>
                          </div>
                          <div class="text-small mb-1">Total</div>
                          <div class="cta-1">{moneyFormat(withdrawnReferralEarnings, country)}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Referrals </span>
                            <i className="fa-regular fa-users text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{referrals}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Contents Shared</span>
                            <i className="fa-regular fa-share-nodes text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{contentsShared}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>Contents Viewed</span>
                            <i className="fa-regular fa-laptop text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{viewed}</div>
                        </div>
                      </div>
                    </div>

                    
                  </div>

                </div>
        </div>  
    )
}

export default UserOverview;