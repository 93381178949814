import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";

const Referrals = () => {

    const [pageTile, setPageTitle] = useState("User Referrals")
    
    const [isLoading, setLoading] = useState(false)
    const [referrals, setReferrals] = useState([])
    const [constPosts, setConstPosts] = useState(20)

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        setLoading(false)
      }

      const getRefs = async () => {
        setLoading(true)
        let x = await requestHandler.get('user/100/referrals', true);
        if(x != null && x.status === 1){
            setReferrals(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      
    useEffect(() => {
        checkIfLoggedIn()
        getRefs()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
             <h2 class="small-title">Referrals</h2>
            {isLoading? <Loading/> : ''}
            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

                <table className="table table-striped">
        <thead>
          <tr> <th colSpan={2}><span className="text-primary">Total:</span> {referrals.length}</th> </tr>
          <tr>
          <th>Date</th>
          <th>Username</th>
          </tr>
          </thead>
          <tbody>
      {referrals.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}</td>
          <td>{x.username}</td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default Referrals;