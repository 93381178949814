
import { useEffect, useState } from "react";
import Loading from "../../utilities/Loading";
import requestHandler from "../../utilities/requestHandler";
import { Link } from "react-router-dom";
import greetings from "../../utilities/greetings";
import images from "../../utilities/images";

const AgentHome = () => {

    const [announcement, setAnnouncement] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [accountType, setAccountType] = useState(localStorage.getItem('account_type'))
    const [lastPopId, setLastPopId] = useState(localStorage.getItem('last_pop_id'))

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            //window.location.href = "/auth/login"
            setLoggedIn(false);
        }
        else{
           // setLoggedIn(true);
        }
      }

      const getStartedInfo = [
        {id: 1, link_val: "/auth/coupon", icon_name: "building", title: "Business Name", body: "Business Name Registration is N25,000. Duration is 7-14 working days and it can be out before then."},
        {id: 1, link_val: "/auth/register", icon_name: "building", title: "Limited Liability", body: "Limited Liability Registration for 1million Shares with Stamp Duty is N70,000. Duration 7-14 working days and it can be out before then."},
        {id: 1, link_val: "#", icon_name: "upload", title: "Upgrade Business Name", body: "Upgrade from Business name to limited liability company for 1million Shares with Stamp Duty(TIN) = N80,000. Duration 7-21 working and it can be out before then."},
        {id: 1, link_val: "/auth/coupon", icon_name: "plus", title: "Others", body: "NGO, Church, Association, Clubs, Mosque Registration, etc + News Paper Publication: N160,000. Duration is 2-3 months and it can be out before then."}
      ]

      const whyChooseUsInfo = [
        {id: 1, link_val: "#", icon_name: "dollar", title: "Steady Income", body: "Enjoy monthly payments ranging from 100k to 200k, with the potential to earn additional incentives of up to 1 million."},
        {id: 1, link_val: "#", icon_name: "laptop", title: "Opportunities for Learning", body: "Combine earning with learning as you develop your skills in sales and marketing, setting yourself up for long-term success."},
        //{id: 2, link_val: "#", icon_name: "cart", title: "Digital Product Marketplace", body: "TheMainceos hosts a vibrant digital marketplace where creators can showcase and sell their digital products to a vast audience."},
        {id: 3, link_val: "#", icon_name: "building", title: "Collaborative Environment", body: "Work alongside reputable organizations like CAC and Themainecos, expanding your network and maximizing your earning potential. "},
        {id: 4, link_val: "#", icon_name: "boxes", title: "Access to Marketing Tools", body: "Gain exclusive access to our affiliate marketing platform and premium marketing kits, empowering you to reach your sales targets effectively."},
        {id: 5, link_val: "#", icon_name: "support", title: "Continuous Support", body: "Benefit from our ongoing resources and support network, ensuring you have everything you need to succeed in your role."},
        {id: 5, link_val: "#", icon_name: "boxes", title: "Outstanding Team Members", body: "United by Success, both Our Team and Customers Stand at the Forefront of Digital Transformation. With the Invention of Cutting-Edge Digital Dynamic Features, We've Catalyzed the Largest Revolution in the Online Space. Leading the Charge are Our Esteemed CEOs, Guiding Us Towards Unprecedented Achievement."},
      ] 

    const getAnnouncement = async () => {
        try{
            let x = localStorage.getItem('last_pop_id');
            if(x !== null) setLastPopId(x)
        }
        catch(e){
            console.log(e)
        }
        let x = await requestHandler.get('admin/announcement', false);
        if(x != null && x.status === 1){
            setAnnouncement(x.message)
            //console.log(x.message)
            if(announcement.id !== undefined){
                
                if(lastPopId === null && parseInt(announcement.id) > 0){
                    setPopUp(true)
                    setLastPopId(x.message.id.toString())
                }
                else if(parseInt(lastPopId) !== parseInt(announcement.id)){
                    setPopUp(true)
                }
                else {
                    localStorage.setItem('last_pop_id', x.message.id.toString())
                    setLastPopId(x.message.id.toString())
                    setPopUp(true)
                }
            }
        }
    }

    const closePopUp = () => {
        setPopUp(false)
    }

    useEffect (()=>{
        //checkIfLoggedIn()
        getAnnouncement()        
    },[announcement])

    const PopUp = () => {
        return(
            <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
                 <div class="fixed-background"></div>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={closePopUp}></button>
          </div>
          <div class="modal-body ps-5 pe-5 pb-0 border-0">
            <h4>{announcement.subject}</h4>
          </div>
          <div class="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                    <div class="justify-content-start">
                        <div style={{whiteSpace: "pre-wrap"}} class="text-normal">{announcement.announcement} </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        )
    }

    return (
        <div class="col">
           
            {popUp? <PopUp/> : ''}
            
            {loggedIn? <Loading/>
            :
            <div className="row">
            <div class="col-md-6 col-xl-8 col-xxl-9 mb-5">                
                
            <div class="col">
              <div class="page-title-container mb-3">
                <div class="row">
                  <div class="col mb-2">
                    <h1 class="mb-2 pb-0 display-4" id="title">{greetings.greet}!</h1>
                    <div class="text-muted font-heading text-small">Welcome To The Main CEOs.</div>
                  </div>
                </div>                
              </div>
              

              <div class="row">
              <div class="col-12 col-lg-12 mb-5">
                  <div class="card sh-30 h-lg-100 position-relative bg-theme">
                  <img src={images.globeGif2} class="card-img h-100 position-absolute theme-filter" alt="card image" />
                    <div class="card d-flex flex-column justify-content-end bg-transparent">
                      <div class="m-3 mb-4">
                        <div class="cta-1 mb-2 w-75 w-sm-50 text-white">THEMAIN CEOS AGENTS</div>
                        <div class="w-50 text-alternate text-white">Become our sales agent and start earning up to 100k monthly.</div>
                      </div>
                      <div>
                        <Link to="/auth/register" class="btn btn-icon btn-icon-start btn-primary m-3 stretched-link">
                          <i data-cs-icon="chevron-right"></i>
                          <span>Get Started</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="mb-5">
                <h2 class="small-title">Why Choose Us?</h2>
                <div class="row g-2 row-cols-1 row-cols-xl-2 row-cols-xxl-4">
                  {whyChooseUsInfo.map(x => <div class="col">
                    <div class="card h-100">
                      <div class="card-body">
                        <div class="text-center">
                        <div class="justify-content-center align-items-center">
                              <div class="fw-bold text-primary">
                              <i data-cs-icon={x.icon_name}></i>
                              </div>
                            </div>
                          <div class="d-flex flex-column sh-5">
                            <Link to="#" class="heading stretched-link">{x.title}</Link>
                          </div>
                        </div>
                        <div class="text-muted">{x.body}</div>
                      </div>
                    </div>
                  </div>)}
                </div>
              </div>
              

                </div>

              </div>
                
            </div>
            <div class="col-md-1 col-xl-4 col-xxl-9 mb-5">

            <h2 class="small-title">CAC Registration Prices</h2>
            <div class="scroll-out mb-5">
                    <div class="scroll-by-count" data-count="4">
                      {getStartedInfo.map( (x) => <div class="card mb-2 hover-border-primary">
                        <a href="/auth/coupon" class="row g-0 sh-12">
                          <div class="col-auto">
                            <div class="sw-9 sh-9 d-inline-block d-flex justify-content-center align-items-center">
                              <div class="fw-bold text-primary">
                              <i data-cs-icon={x.icon_name}></i>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="card-body d-flex flex-column ps-0 pt-0 pb-0 h-100 justify-content-center">
                              <div class="d-flex flex-column">
                                <div class="text-alternate"> {x.title}</div>
                                <div class="text-small text-muted">{x.body}</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>)}
                    </div>
                  </div>


            {/*<PopularContent/>*/}
            </div>
            </div>
            }
            
            </div>
    )
}

export default AgentHome;