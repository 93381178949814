import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"

const PackageLimitations = () => {

    const [pageTile, setPageTitle] = useState("Packages & Limitations")
    const [about, setAbout] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const websiteSettings = async () => {
        setLoading(true)
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            let y = x.message;
            setAbout(x.message.about)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        document.title = pageTile
        websiteSettings()
    },[pageTile])

    return(
        <div className="col">
          <h2 class="small-title">{pageTile}</h2>
          {isLoading? <Loading/> : ''}
<div class="row g-2">

              <div className="col-md-4">
              <div class="card">
              <div class="card-body">
              <div style={{whiteSpace: "pre-wrap"}}>
              <h2 class="small-title">CEO Package</h2>
              - Registration fee: ₦3,000 <br/><br/>
- Commission: ₦1,500<br/><br/>
- 50% commission rate<br/><br/>
- Access to one telegram group for training<br/><br/>
- Limited conversion of activities<br/><br/>
- Access to agents with low commission rates<br/><br/>
- Weekly payment as an affiliate<br/><br/>
- Monthly payment as an agent<br/><br/>
- Right to agent account on the site<br/><br/>
- Right to promote any course or service<br/><br/>
- Right to be a sales agent and team member in the community<br/><br/>
 -Right to learn skills monthly
                </div>
                </div>
                </div>
              </div>

              <div className="col-md-4">
              <div class="card">
              <div class="card-body">
              <div style={{whiteSpace: "pre-wrap"}}>
              <h2 class="small-title">Mainceos Package</h2>
              - Registration fee: ₦5,000<br/><br/>
- 50% commission rate for referrals<br/><br/>
- Access to telegram and mentorship classes on WhatsApp<br/><br/>
- Conversion of activity points with limitations<br/><br/>
- Right to register business brand name with CAC at a discount<br/><br/>
- Right to place products on the marketplace<br/><br/>
- Weekly payment for affiliates<br/><br/>
- Monthly payment for agents<br/><br/>
- Right to agent account on the site<br/><br/>
- Right to promote any course or service<br/><br/>
- Right to be a sales agent and team member in the community<br/><br/>
-Right to learn skills monthly 
                </div>
                </div>
                </div>
              </div>

              <div className="col-md-4">
              <div class="card">
              <div class="card-body">
              <div style={{whiteSpace: "pre-wrap"}}>
              <h2 class="small-title">Themainceos Package: Executives Account</h2><br/>
              - Registration fee: ₦70,000<br/><br/>
- Commission bonus: ₦10,000<br/><br/>
- Free Enterprise CAC registration<br/><br/>
- Access to social media presence creation or optimization<br/><br/>
- Private mentorship classes on wealth creation with the CEO<br/><br/>
- Accredited agent for CAC with full knowledge on pre and post-registration<br/><br/>
- Access to run free advertising for 3 months (advertising budget paid separately)<br/><br/>
- Access to agent portal for earning massive commission<br/><br/>
- Potential to earn ₦1 million in the first month as a partner<br/><br/>
- Access to paid courses for 1 year<br/><br/>
- Free branding for sales agent kits<br/><br/>
- Access to real estate branding and marketing kits<br/><br/>
- Weekly payment for affiliates<br/><br/>
- Monthly payment for agents<br/><br/>
- Right to agent account on the site<br/><br/>
- Right to promote any course or service<br/><br/>
- Right to be a sales agent and team member in the community<br/><br/>
-right to learn free skills monthly
                </div>
                </div>
                </div>
              </div>

</div>

        </div>  
    )
}

export default PackageLimitations;