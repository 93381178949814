import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"

const About = () => {

    const [pageTile, setPageTitle] = useState("About")
    const [about, setAbout] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const websiteSettings = async () => {
        setLoading(true)
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            let y = x.message;
            setAbout(x.message.about)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        document.title = pageTile
        websiteSettings()
    },[pageTile])

    return(
        <div className="col">
          <h2 class="small-title">About</h2>
          {isLoading? <Loading/> : ''}
<div class="row g-2">

              <div class="card">
              <div class="card-body">
              <div style={{whiteSpace: "pre-wrap"}}>
                {about} 
                </div>
                </div>

                </div>

</div>

        </div>  
    )
}

export default About;