import React, { Component, useEffect, useState } from 'react';

import logos from '../../../../../utilities/logos';
import NumberToNaira from '../../constants/numberToNaira';
import urls from '../../../../../utilities/urls';
import ErrorMessage from '../../../../../utilities/errorMessage';
import SuccessMessage from '../../../../../utilities/successMessage';
import LoadingImage from '../loadingImage';

import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import images from '../../../../../utilities/images';
import requestHandler from '../../../../../utilities/requestHandler';


const Dashbaord = () => {

    const services = [
        /*{
            id: 1,
            service: "WALLETS",
            path: "wallet",
            subText: "View",
            img: logos.wallet 
        },*/
        {
          id: 2,
          service: "DATA",
          path: "none_sme_data",
          subText: "Subscribe",
          img: logos.data 
      },
      {
            id: 4,
            service: "SME DATA",
            path: "mtn_special",
            subText: "Subscribe",
            img: logos.data 
        },
        {
            id: 3,
            service: "AIRTIME",
            path: "buy_airtime",
            subText: "Purchase",
            img: logos.airtime 
        },
        {
            id: 5,
            service: "PRINT AIRTIME",
            path: "print_airtime",
            subText: "Print",
            img: logos.print_airtime 
        },
        /*
        {
            id: 6,
            service: "BULK SMS",
            path: "buy_bulk_sms",
            subText: "Purchase",
            img: logos.sms 
        },
        */
        /*{
            id: 7,
            service: "BULK SMS",
            path: "send_sms",
            subText: "Send SMS",
            img: logos.sms 
        },*/
        {
            id: 8,
            service: "CABLE TV",
            path: "cable_tv",
            subText: "Subscribe",
            img: logos.cable_tv 
        },
        {
            id: 9,
            service: "ELECTRICITY",
            path: "electricity",
            subText: "Pay bill",
            img: logos.electricity 
        },
        /*
        {
            id: 10,
            service: "FOREIGN AIRTIME",
            path: "foreign_airtime",
            subText: "Purchase",
            img: images.international 
        },
        {
            id: 11,
            service: "EDUCATIONAL PINS",
            path: "educational_pins",
            subText: "Purchase",
            img: images.educational 
        }  */   
    ]
    
    const [userData, setUserData] = useState([]);
    const [wallet, setWallet] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [pendingPackage, setPendingPackage] = useState([]);
    const [pendingPackage_, setPendingPackage_] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('access_token'));
    const [errMsg, setErrMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [pin, setPin] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [enterCoupon, setEnterCoupon] = useState(false);

    const [coupon, setCoupon] = useState(null);

    const [announcementStoredId, setAnnouncementStoredId] = useState(localStorage.getItem('announcementId'));
    const [announcement, setAnnouncement] = useState([]);
    const [showModal, setShowModal] = useState(false);

    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +token          
        }
      }

    const closeAnnouncement = () =>{
        localStorage.setItem('announcementId', announcement.id)
        setShowModal(false)
      }

      const getAnnouncement = () => {
        if(token !== null){
        fetch(urls.apiurl +'vtu/user/announcement')
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 1) {
                  setAnnouncement(json.result)
                  setShowModal(true)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => console.log("done"));
          }
      }
      
    function CouponCode () {
        setEnterCoupon(true)
    }


    /*/////MONNIFY//////*/
    const checkIfMonifyHasPendingTransaction = () => {

        fetch(urls.apiurl +'vtu/monnify/login/')
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {    
                  setMonnifyToken(JSON.parse(JSON.stringify(json.token)))                    
                  //(json.token)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

        if(monnifyToken != null){ 
                             
              const postOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                   token: monnifyToken,
                   user_reference: monnifyReference
                 })
            };

              setLoading(true)
        fetch(urls.apiurl +'vtu/monnify/check_pending_transfer/', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) { 
                  
                  getUserDetails();
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
            }
      }

      const [monnifyToken, setMonnifyToken] = useState(null);
      const [monnifyReference, setMonnifyReference] = useState(null);
      const monnifyDetails = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/user/monnify_details/', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {   
                    
                  setMonnifyReference(json.monnify.account_reference)

                  //add more to wallet if pending transfer found
                 // checkIfMonifyHasPendingTransaction()
                  
                }else{
                  
                  createMonnifyAccount();
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const createMonnifyAccount = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/monnify/login/', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {    
                  setMonnifyToken(JSON.parse(JSON.stringify(json.token)))                    
                  //(json.token)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
            
            if(monnifyToken !== null){
              
              const postOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +token  
                },
                body: JSON.stringify({ 
                   token: monnifyToken,
                    email: userData.email,
                    username: userData.username
                 })
            };

              setLoading(true)
        fetch(urls.apiurl +'vtu/monnify/reserveAccount/', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) { 
                  //get monnify details 
                  
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
            }
      }
    /*////MONNIFY/////*/
    
    const activateWithCoupon = () => {
        setErrMsg(null)
        setLoading(true)
    
        const postOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +token          
              },
            body: JSON.stringify({ 
                    coupon: coupon,
                    username: userData.username,
                    package: userData.package
             })
        };
    
        fetch(urls.apiurl + 'vtu/coupon/activate_with_coupon', postOptions)
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.status === 1) {
                            setErrMsg(null)
                            setLoading(false)
                            setPendingPackage([])
                            setPendingPackage_(false)
                            setSuccessMsg(json.message)
                        }
                        else {
                            setErrMsg(json.message)
                            setLoading(false)
                        }
                    })
                    .catch((error) => console.error(error))
                    .finally(() => setLoading(false));
    }

    const getUserDetails = async () => {
        setLoading(true)
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            setUserData(x.message)
                    setWallet(y.wallet)
                    getTransactions(); 
        }else{
           // window.location.replace("/login");
        }
    }


    const checkPendingPackage = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/user/check_pending_package', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 1) {
                    setPendingPackage(json.result)
                    setPendingPackage_(true)

                }else{
                    setPendingPackage([])
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const getTransactions = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/transaction/10/transactions', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 1) {
                    setTransactions(json.result)
                    //console.log("dataa"+ transactions)
                }
            })
            .catch((error) => console.error("Goterr "+error))
            .finally(() => setLoading(false));
    }


    const activatePackageWithWallet = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/transaction/activate_package_from_wallet', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 1) {
                    setSuccessMsg(json.message)
                    //console.log("dataa"+ transactions)
                }
                else if(json.result == "Empty"){
                    window.location.replace("/user/fund_wallet");
                }
                else{
                    setErrMsg(json.message)
                }
            })
            .catch((error) => console.error("Goterr "+error))
            .finally(() => setLoading(false));
    }


    useEffect(()=>{
        if(userData == ""){
            getUserDetails();             
            //checkPendingPackage();         
        }

        createMonnifyAccount(); 
        monnifyDetails();
            
        

        //redirectBrowserNotSupported();
        getAnnouncement();

    }, [transactions])


    const announcementModal = <div
    className="modal show"
    style={{ display: 'block', position: 'initial' }}
    >
    <Modal show={true} onHide={false}>
      <Modal.Header onClick={()=>closeAnnouncement()} closeButton>
        <Modal.Title>{announcement.subject}</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>{announcement.message}</p>
      </Modal.Body>
    
      <Modal.Footer>
        <Button onClick={()=>closeAnnouncement()} variant="secondary">Close</Button>
      </Modal.Footer>
    </Modal>
    </div>
  
    return <div class="col">
        <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
          <div class="row">

          <div class="col-xl-12 col-12">
                  {successMsg != null? <div class="col-xl-12 col-lg-12">
          <div class="box-body text-center">
              <SuccessMessage message={successMsg}/>
              </div>
              </div>
               : null  }

{errMsg != null? <ErrorMessage message={errMsg}/> : null  }
              </div>

{/* userData.package == "Free Account" ? 
          <div className='card'>
          <div class="box-body text-center">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <h6 class="my-0"><span className='text-danger'> Free Account! </span></h6>
                                  <p class="mb-0 text-fade">{NumberToNaira(0)}</p>
                              </div>
                              <div class="b-1"></div>
                              <div>
                                <Link to={"/user/upgrade"} className='btn btn-success m-5'>
                                  UPGRADE <i className='fa fa-arrow-up'></i>
                                  </Link>
                              </div>
                          </div>
                      </div>                      
          </div>
         : null */}

       

          {pendingPackage_ ? 
          <div class="col-xl-12 col-lg-12">
<div class="box">

{enterCoupon ?
<div class="box-body text-center">
<div class="input-group mb-3">
      <input class="form-control" type="text" placeholder="Enter Coupon Code"
      value={coupon}
      onChange={e => setCoupon(e.target.value)} />
      {isLoading? <LoadingImage /> :
                                <button onClick={()=>activateWithCoupon()} class="btn btn-primary btn-sm"> Activate Package </button>
                            }
</div>
</div>
:
                      <div class="box-body text-center">
                          <div class="d-flex justify-content-around">
                              <div>
                                  <h6 class="my-0">{pendingPackage.package} <span className='text-danger'> - Pending</span></h6>
                                  <p class="mb-0 text-fade">{NumberToNaira(pendingPackage.amount)}</p>
                              </div>
                              <div class="b-1"></div>
                              <div>
                              {isLoading? <LoadingImage /> :
                                <button onClick={() => activatePackageWithWallet()} className='btn btn-primary m-5'>Pay Now!</button>
                            }
                              
                                <button type='button' onClick={() => CouponCode()} className='btn btn-success m-5'>
                                  Use PIN
                                  </button>
                              </div>
                          </div>
                      </div>
                       }
                  </div>
          </div>
        : null}

  <div className='row'>
          {services.map(x =>
            <div class="col-4">
                <Link to={"/vtu/user/"+x.path}>  
                  <div class="box">
                      <div class="d-flex d-flex justify-content-center">
                          <div class="d-flex flex-column justify-content-center">
                              <div className='justify-content-center' style={{
                                height: 50, 
                                width: 50,
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                                display: `inline-block`,
                                alignSelf: `center`
                              }} >
                                  <img className='rounded-circle' src={x.img} style={style.logo} />
                              </div>
                              <div className='d-flex justify-content-center text-center'>
                              <h6 class="justify-content-center my-0 mt-1 mb-3 text-lowercase text-center">{x.service}</h6>
                                 {/* <p class="mb-0 text-fade">{x.subText}</p>*/}
                                 </div>
                          </div>
                      </div>
                  </div>
                  </Link>
              </div>
              
          )}
              </div>

          </div>	
          <div class="row">
              <div class="col-xl-12 col-12">

{/*                  <div class="box position-static">
                      <div class="box-header">
                          <h4 class="box-title">Account Overview</h4>
                         </div>
                      <div class="box-body">
                          <div class="row mb-20">
                                 <div class="row">
                                  
                                 <div class="col-xl-4 col-12">
				<div class="box box-body bg-success">
				  <h6 class="text-uppercase text-white">Package</h6>
				  <div class="flexbox mt-2">
					<span class=" fs-30">{userData.package}</span>
					<span class="fa fa-cube fs-40"><span class="path1"></span><span class="path2"></span></span>
				  </div>
				</div>
			</div> 

            <div class="col-xl-4 col-12">
				<div class="box box-body bg-primary">
				  <h6 class="text-uppercase text-white">Referrals</h6>
				  <div class="flexbox mt-2">
					<span class=" fs-30">{userData.referrals}</span>
					<span class="fa fa-users fs-40"><span class="path1"></span><span class="path2"></span></span>
				  </div>
				</div>
			</div>

            <div class="col-xl-4 col-12">
				<div class="box box-body bg-primary">
				  <h6 class="text-uppercase text-white">Referral Commission</h6>
				  <div class="flexbox mt-2">
					<span class=" fs-30">{NumberToNaira(wallet.referral_commission)}</span>
					<span class="fa fa-users fs-40"><span class="path1"></span><span class="path2"></span></span>
				  </div>
				</div>
			</div>
                                
                  
                  
            <div class="col-xl-4 col-12">
				<div class="box box-body bg-info">
				  <h6 class="text-uppercase text-white">Cashback Allocated</h6>
				  <div class="flexbox mt-2">
					<span class=" fs-30">{NumberToNaira(wallet.cashback_balance)} </span>
					<span class="fa fa-database fs-40"><span class="path1"></span><span class="path2"></span></span>
				  </div>
				</div>
			</div>

            <div class="col-xl-4 col-12">
				<div class="box box-body bg-info">
				  <h6 class="text-uppercase text-white">Cashback Withdrawn</h6>
				  <div class="flexbox mt-2">
					<span class=" fs-30">{NumberToNaira(wallet.cashback_withdrawn)} </span>
					<span class="fa fa-bank fs-40"><span class="path1"></span><span class="path2"></span></span>
				  </div>
				</div>
			</div>

            <div class="col-xl-4 col-12">
				<div class="box box-body bg-info">
				  <h6 class="text-uppercase text-white">Cashback Pending</h6>
				  <div class="flexbox mt-2">
					<span class=" fs-30">{NumberToNaira(wallet.cashback_pending)} </span>
					<span class="fa fa-refresh fs-40"><span class="path1"></span><span class="path2"></span></span>
				  </div>
				</div>
			</div>

                              </div>
                          </div>
                          
                      </div>
                  </div>*/}

                  <div class="row">					
                  <div class="col-xl-12 col-12">
                  <div class="box">
                      <div class="box-header with-border">
                        <h4 class="box-title">Last 10 Transactions</h4>
                      </div>
                      <div class="box-body pt-0">
                          <div class="table-responsive">
                            <table class="table mb-0">
                            <tr>
                                <td><a href="javascript:void(0)">Type</a></td>
                                <td><span class="text-muted text-nowrap">
                                     Date</span> </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="mx-5">Amount</span>
                                    </div>
                                </td>
                                <td class="text-end">Status</td>
                              </tr>
                            {transactions.map(tr =>
                              <tr>
                                <td><a href="#">{tr.type}</a></td>
                                <td><span class="text-muted text-nowrap">
                                    {tr.created_at} </span> </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="mx-5">{NumberToNaira(tr.amount)}</span>
                                    </div>
                                </td>
                                {tr.status == 0 ? <td class="text-end text-danger">Pending</td> : ''}
                                {tr.status == 1 ? <td class="text-end text-success">Successful</td> : ''}
                                {tr.status == 2 ? <td class="text-end text-warning">Declined</td> : ''}
                              </tr>
                            )}
                             </table> 
                          </div>
                      </div>
                  </div>
              </div>				
                    
                          </div>					
              </div>
            
          </div>	
      </section>
    </div>
</div>
<div class="control-sidebar-bg"></div>  
{showModal?announcementModal : ''}
</div>
;

}

export default Dashbaord;

const style = {
    logo: {width: 45, heiht: 45}
};