import { useEffect } from "react";
import requestHandler from "../../utilities/requestHandler";

const LogOut = () => {

    const logOut = async () => {
        let x = await requestHandler.get('auth/logout', true);
        if(x != null && x.status === 1){
          //do something after login
        }
        localStorage.removeItem('access_token')
          localStorage.removeItem('account_type')
          window.location.href = "/auth/login"
      }

      useEffect(() => {
        logOut()
      })
}
export default LogOut;