import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import Loading from "../../utilities/Loading"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import requestHandler from "../../utilities/requestHandler"

const UpdatePassword = () => {

    const [pageTile, setPageTitle] = useState("Update Password")
    const [password, setPassword] = useState(null);
    const [password1, setPassword1] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login/redirect"
      }
    }

    useEffect(() => {
      checkIfLoggedIn()
        document.title = pageTile
    },[pageTile])

    const updatePasswordAction = async () => {
        setLoading(true)

        //setData({email: email, password: password})
        let x = await requestHandler.post('settings/update/password', {password: password, password1: password1, password2: password2}, false, true)
            if(x != null && x.status === 1)
            {
                toast.success(x.message)
                setPassword('')
                setPassword1('')
                setPassword2('')
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else
            {
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

    return(
        <div className="col">
        <h2 className="small-title">Update Password</h2>
                  <form className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} className="form-control" placeholder="Current Password"/>
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-on"></i>
                        <input value={password1} onChange={e => setPassword1(e.target.value)} className="form-control" type="password" placeholder="New Password" />
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-on"></i>
                        <input value={password2} onChange={e => setPassword2(e.target.value)} className="form-control" type="password" placeholder="Confirm Password" />
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={updatePasswordAction} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Update</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                  </form>
        </div>  
    )
}

export default UpdatePassword;