import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link, useParams } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";

const UserReferrals = () => {

    const [pageTile, setPageTitle] = useState("User Referrals")
    const {username} = useParams();
    const [isLoading, setLoading] = useState(false)
    const [referrals, setReferrals] = useState([])

    const getRefs = async () => {
        setLoading(true)
        let x = await requestHandler.get('admin/user/100/'+username+'/referrals', true);
        if(x != null && x.status === 1){
            setReferrals(x.message)
        }
        else{
           // toast.error(x.message)
        }
        setLoading(false)
      }
      
    useEffect(() => {
        getRefs()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
             <h2 class="small-title">Referrals</h2>
            {isLoading? <Loading/> : ''}
            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

        <table className="table table-striped">
        <thead>
          <tr> <th colSpan={2}><span className="text-primary">Total:</span> {referrals.length}</th> </tr>
          <tr>
          <th>Date</th>
          <th>Username</th>
          <th>Details</th>
          </tr>
          </thead>
          <tbody>
      {referrals.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}</td>
          <td>{x.username}</td>
          <td><Link to={'/admin/user/'+x.id+'/'+x.email} class="btn btn-outline-primary btn-sm ms-1" type="button">View</Link></td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default UserReferrals;