import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"

const OurServices = () => {

    const [pageTile, setPageTitle] = useState("Our Services")
    const [about, setAbout] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const websiteSettings = async () => {
        setLoading(true)
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            let y = x.message;
            setAbout(x.message.about)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        document.title = pageTile
       // websiteSettings()
    },[pageTile])

    return(
        <div className="col">
          <h2 class="small-title">{pageTile}</h2>
          {isLoading? <Loading/> : ''}
<div class="row g-2">

              <div class="card">
              <div class="card-body">
              <div style={{whiteSpace: "pre-wrap"}}>
              Here's what we offer to help you thrive in business and personal growth:
              
<br/><br/><b>1. Entity Formation Services</b>
   <br/>- Business Name Registration
   <br/>- Company Registration
   <br/>- Group of Companies Formation
   <br/>- Limited by Guarantee (LTD/GTE) Establishment
   <br/>- Incorporated Trustees for NGOs, Foundations, and Associations

<br/><br/><b>2. Digital Presence And Marketing</b>
   <br/>- Website Design and Development
   <br/>- Social Media Marketing Services
   <br/>- Social Media Management (with expert managers for hire!)
   <br/>- Google Business Listing Optimization
   <br/>- Graphic Design, Logo Creation, and Video Content

<br/><br/><b>3. Regulatory Compliance Solutions</b>
   <br/>- Tax Identification Number (TIN) Registration (JTB & FIRS)
   <br/>- SCUML Compliance
   <br/>- Trustees Declaration
   <br/>- Export License Assistance
   <br/>- NIN Modification

<br/><br/><b>4. Intellectual Property Protection</b>
   <br/>- Patent Registration
   <br/>- Copyright Protection
   <br/>- Trademark Registration

<br/><br/><b>5. Corporate Governance And Documentation</b>
   <br/>- Annual Returns Filing
   <br/>- All CAC Post-Incorporation Services
   <br/>- Newspaper Publications
   <br/>- Court Affidavit and CTC Affidavit Services

<br/><br/><b>6. Training & Mentorship</b>
   <br/>- Entrepreneurial Workshops
   <br/>- Professional Development Seminars
   <br/>- Digital Marketing Training
   <br/>- Business Mentorship Programs
   <br/>- Career Advancement Courses
   <br/>- Leadership Training

<br/><br/><b>7. Music Promotion Services</b>
   <br/>- Distribution to all major streaming platforms
   <br/>- Social Media Promotion across multiple platforms
   <br/>- Artist Promotion to showcase talent and story
   <br/>- Audience Engagement to build a loyal fanbase
   <br/>- Analytics to track music performance
   <br/>- Monetization strategies for revenue optimization
   <br/><br/>

<b>Elevate your music career with us!</b> 🎶 <br/>

As an affiliate or a sales agent, you can choose any of these services to advertise and get paid for any successful transaction you make! Join us and turn your passion into profit while making dreams come true!<br/><br/>

Choose Themainceos for all your business needs and personal growth aspirations! With our additional services such as music promotion, distribution, and social media marketing, you'll take your ventures to new heights!<br/><br/>

With Themainceos, you can't go wrong! 🚀
                </div>
                </div>

                </div>

</div>

        </div>  
    )
}

export default OurServices;