import { useEffect, useState } from "react";
import requestHandler from "../../utilities/requestHandler";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loading from "../../utilities/Loading";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import RecentContents from "./recentContents";
import UpdateContent from "./updateContent";
import { RWebShare } from "react-web-share";
import truncate from "../../utilities/truncateString";
import urls from "../../utilities/urls";
import Helmet from 'react-helmet';
import dateTimeToDate from "../../utilities/dateTimeToString";
import RandomString from "../../utilities/randomString";
import ContentDetails from "./contentDetails";
 
const ViewContent = ({route}) => {

  const [pageTile, setPageTitle] = useState("Content")
  const [shareUrl, setShareUrl] = useState(null)
    const [newUniqueId, setNewUniqueId] = useState(null)
  const {uniqueId} = useParams()
    const {userId} = useParams()
    const [isLoading, setLoading] = useState(false)
    const [post, setPost] = useState([])
    const [postViewed, setPostViewed] = useState(false)
    const [editPost, setEditPost] = useState(false)
    const account_type = localStorage.getItem("account_type")
    const [userData, setUserData] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [sessionId, setSessionId] = useState(null)
    const [profileGotten, setProfileGotten] = useState(false)

    const userProfile = async () => {
      setLoading(true)
      let x = await requestHandler.get('user/profile', true);
      if(x != null && x.status === 1)
      {
          let y = x.message;
          setUserData(y[0])
          setLinkToShare(y[0].user_id, uniqueId)
          
      }
      else {
        setLinkToShare(null, uniqueId)
      }
      
      //get post
      //alert("hey")
      getContents()

      setLoading(false)
    }

    const getContents = async () => {
      setLoading(true)
      //alert(sessionId)
        //if(sessionId != null){
        let curUrl = 'content/'+userId+'/'+uniqueId+sessionId+'/view'
        if(userId === undefined){
          curUrl = 'content/'+uniqueId+'/view'
        }
        
        let x = await requestHandler.get(curUrl, true);
        if(x != null && x.status === 1)
        {
            setPost(x.message)
            setNewUniqueId(x.message.unique_id)
            setPageTitle(x.message.post_title)
            setPostViewed(true)
            console.log(x.message)
        }
        else
        {
            toast.error(x.message)
        }
      
      
      //}
        setLoading(false)
      }
    
      const deleteBtn = async (id) => {
        let canDelete = window.confirm("Do you want to delete post?")
        if(canDelete){

        setLoading(true)
        let x = await requestHandler.get('content/'+id+'/delete', true);
        if(x != null && x.status === 1){
            toast.success(x.message)
            window.location.href="/content/all"
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
    }

      }

      const ShareBtn = () => {

        return <RWebShare
                data={{
                    text: post.post_title,
                    url: shareUrl,
                    title: "Share - "+post.post_title,
                }}
                onClick={() => shareContent() }
            >
                      <button class="btn btn-sm btn-icon btn-outline-primary ms-1" type="button">
                      Share <i class="fa-regular fa-share text-center"></i>
                      </button>
                      </RWebShare>
      }

      const shareContent = async () =>{
        setLoading(true)
        let curUrl = 'content/'+userId+'/'+uniqueId+'/share'
        if(userId === undefined){
          curUrl = 'content/'+uniqueId+'/share'
        }
        let x = await requestHandler.get(curUrl, true);
        if(x != null && x.status === 1){
            //toast.success(x.message)
            //window.location.href="/content/all"
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const setLinkToShare = (user_id, post_uid) =>{
        if(user_id !== null){
          const shareUrl_ = urls.appUrl+"content/"+user_id+"/"+post_uid+"/view"
            setShareUrl(shareUrl_)
            }
          else setShareUrl(urls.appUrl+"content/"+post_uid+"/view")
      }

      const setSession = () => {
        //set user unique session
        let randStr = RandomString(15)
        let session_Id = localStorage.getItem('sessionId')
        if(session_Id == null){
          setSessionId(randStr)
          localStorage.setItem('sessionId', randStr)
        }
      }

    useEffect(() => {      
      setSession()
      userProfile()
      document.title = pageTile
        //setLinkToShare()
    },[uniqueId])

    

    const Content = () => {
       return <div class="card mb-5">
        <Helmet>
                <title>{pageTile}</title>
                <meta name={pageTile} 
                content={pageTile} />
            </Helmet>

        <div class="card-body p-0">
                  <div class="glide glide-gallery" id="glideBlogDetail">
                    <div class="glide glide-large">
                    { post.post_video === null?
                      <img src={post.post_image} class="card-img-top sh-19" alt="card image" />
                  :
                    <video src={post.post_video} className="responsive border-0 rounded-top-end rounded-top-start img-fluid mb-3 w-100" alt="video" controls autoPlay loop></video>
                  }     
                    <div style={{position: 'fixed', zIndex: 500, bottom: 100, padding: 10, right: 10}} class="text-muted d-lg-none">
                    <div class="row">
                    <ShareBtn />
                    </div>
                  </div>

                    </div>
                    
                  </div>
                  <div class="card-body pt-0">

                    <h4 class="mb-3">{post.post_title}</h4>
                    <p className="text-muted">
                              {dateTimeToDate(post.post_date)} | by {post.username}
                            </p>
                    <div style={{whiteSpace: "pre-wrap"}}>
                            {post.post_content}                            
                    </div>

                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                <div class="row align-items-center">

                  <div class="col-8 text-muted">
                    <div class="row g-0">
                    <div class="col-auto pe-3">
                          <span class="align-middle"> <i class="fa-regular fa-eye m-1"></i>  {number_format(post.clicks)}</span>
                          </div>
                          <div class="col">
                            <span class="align-middle">  <i class="fa-regular fa-share m-1"></i>{number_format(post.shares)} </span>
                          </div>
                          {/*<div class="col">
                            <span class="align-middle">  <i class="fa-regular fa-clock m-1"></i><TimeInWords time={post.duration} /> </span>
    </div>*/}
                    </div>
                  </div>

                  
                  <div class="col-4 d-none d-lg-block">
                    <div class="d-flex align-items-center justify-content-end ">
                    <ShareBtn />
                    </div>
                  </div>

                </div>
              </div>
            </div>
    }


    return(
        <div className="col">

<div class="row">
            <div class="col-12 col-xl-8 col-xxl-9 mb-5">
                {isLoading? <Loading/> : ''}
                <div style={{display: userData.account_type === "Super Admin" && !editPost? 'block' : 'none'}}>
                  <div class="col-12 col-md-12 d-flex align-items-start justify-content-start m-1">
                <button onClick={() => editPost? setEditPost(false) : setEditPost(true) } type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto m-1">
                  <i data-cs-icon="edit-square"></i>
                  <span>Edit</span>
                </button>
                <button onClick={() => deleteBtn(post.id)} type="button" class="btn btn-danger m-1">Delete</button>
                {/*<button onClick={() => {showDetails? setShowDetails(false) : setShowDetails(true)}} type="button" class="btn btn-success m-1">{showDetails? 'Hide Overview' : 'Video Overview'}</button>*/}
              </div>
              </div>

                {editPost? <UpdateContent
                id={post.id}
                unique_id={uniqueId}
                post_title={post.post_title}
                post_video={post.post_video}
                post_image={post.post_image}
                post_content={post.post_content}
                status={post.status}
                duration={post.duration}
                size={post.size}
                post_date={post.post_date}
                /> : showDetails? <ContentDetails id={post.id} /> : <Content/> }

            </div>

            <RecentContents />

            </div>
        
        </div>  
    )
}

export default ViewContent;