const bgImage = require('../images/hero.jpeg');
    const bgBlank = require('../images/blank_bg.jpeg');
    const profileImage = require('../images/profile.jpg');
    const logo = require('../images/logo.png');
    const tmc1 = require('../images/tmc1.png');
    const tmc2 = require('../images/tmc2.png');
    const tmc3 = require('../images/tmc3.png');
    const tmc4 = require('../images/tmc4.png');
    const tmc5 = require('../images/tmc5.jpeg');
    const whatsapp = require('../images/whatsapp.png');
    const telegram = require('../images/telegram.png');
    const build_app_website_banner = require('../images/build_app_website_banner.png');
    const globeGif1 = require('../images/globe1.gif');
    const globeGif2 = require('../images/globe2.gif');
    const earn_extra = require('../images/earn_extra.jpeg');

const nmobile = require("../images/services/9mobile.jpg");
const aedc = require("../images/services/aedc.jpg");
const airtel = require("../images/services/airtel.jpg");
const dstv = require("../images/services/dstv.jpg");
const gotv = require("../images/services/gotv.jpg");
const ibedc = require("../images/services/ibedc.jpg");
const ikedc = require("../images/services/ikedc.jpg");
const ekedc = require("../images/services/ekedc.jpg");
const jedc = require("../images/services/jedc.jpg");
const kdedc = require("../images/services/kdedc.jpg");
const kedc = require("../images/services/kedc.jpg");
const mtn = require("../images/services/mtn.jpg");
const phedc = require("../images/services/phedc.jpg");
const startimes = require("../images/services/startimes.jpg");
const eedc = require("../images/services/eedc.jpeg");
const bulksms = require("../images/services/bulksms.png");
const glo = require("../images/services/glo.png");
const voicesms = require("../images/services/voicesms.png");
const international = require("../images/services/international.jpg");
const loader = require("../images/loader.gif");
const educational = require("../images/services/educational.png");
const images = {bgImage, 
    profileImage, 
    bgBlank, 
    logo, 
    build_app_website_banner, 
        globeGif1,
        globeGif2,
        earn_extra,
        tmc1, tmc2, tmc3, tmc4, tmc5, whatsapp, telegram,
        bulksms, voicesms, glo, ekedc, loader,
        eedc, mtn, phedc, startimes,
        kdedc,
        kedc,
        nmobile,
        aedc,
        airtel,
        dstv,
        gotv,
        ibedc,
        ikedc,
        jedc,
        international,
        educational}
        
export default images
