import { Outlet } from "react-router-dom";
import LeftMenu from "../leftMenu";
import Footer from "../footer";
import requestHandler from "../../utilities/requestHandler";
import { useEffect } from "react";
import Header from "../header";

const MainHomeLayout = () => {

  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x != null && x.status === 0){
       // window.location.href = "/auth/login/redirect"
    }
  }

  useEffect(() => {
    checkIfLoggedIn()
  },[])

return (
  <>
 <Header />
  <main>
  <div class="container">
    <div class="row">

<Outlet />

  </div>
  </div>
  </main>
  <Footer/>
  </>
)
}
export default MainHomeLayout;