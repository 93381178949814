import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";

const BankAccounts = () => {

    const [pageTile, setPageTitle] = useState("Banks and Mobile Money")
    
    const [isLoading, setLoading] = useState(false)
    const [banks, setBanks] = useState([])
    const [bankName, setBankName] = useState(null)
    const [accountName, setAccountName] = useState(null)
    const [accountNumber, setAccountNumber] = useState(null)

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        setLoading(false)
      }

      const getBankAccts = async () => {
        setLoading(true)
        let x = await requestHandler.get('user/bank/all', true);
        if(x != null && x.status === 1){
            setBanks(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const addNewBankAccount = async () =>{
        let url = 'user/bank/new';
        setLoading(true)
        let x = await requestHandler.post(url, {bank_name: bankName, account_name: accountName, account_number: accountNumber}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
                getBankAccts()
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

      
    useEffect(() => {
        checkIfLoggedIn()
        getBankAccts()
        document.title = pageTile
    },[])

    return(
        <div className="col">
             <h2 class="small-title">Banks / Mobile Money</h2>
            {isLoading? <Loading/> : ''}

            <form style={{display: banks.length < 1? 'flex' : 'none'}} className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="mb-3 filled">
                        <input type="text" value={bankName} onChange={e => setBankName(e.target.value)} className="form-control" placeholder="Bank or Mobile Network"/>
                      </div>
                      <div className="mb-3 filled">
                        <input value={accountName} onChange={e => setAccountName(e.target.value)} className="form-control" type="text" placeholder="Account Name" />
                      </div>
                      <div className="mb-3 filled">
                        <input value={accountNumber} onChange={e => setAccountNumber(e.target.value)} className="form-control" type="tel" placeholder="Number" />
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={addNewBankAccount} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Submit</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                  </form>

            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

                <table className="table table-striped">
        <thead>
          <tr> <th colSpan={4}><span className="text-primary">Total:</span> {banks.length}</th> </tr>
          <tr>
          <th>Date</th>
          <th>Bank / Mobile Name</th>
          <th>Account Name</th>
          <th>Number</th>
          </tr>
          </thead>
          <tbody>
      {banks.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}</td>
          <td>{x.bank_name}</td>
          <td>{x.account_name}</td>
          <td>{x.account_number}</td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default BankAccounts;