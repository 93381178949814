import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { toast } from "react-toastify"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import images from "../../utilities/images"
import { Link } from "react-router-dom"

const AppInfo = () => {

    const [pageTile, setPageTitle] = useState("App Information")

    const [maintain, setMaintain] = useState(null);
    const [title, setTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [about, setAbout] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [url, setUrl] = useState(null);
    const [phone1, setPhone1] = useState(null);
    const [phone2, setPhone2] = useState(null);
    const [email, setEmail] = useState(null);
    const [officeAddress, setOfficeAddress] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const putToMaintenance = () => {
        if(maintain == 1){
            setMaintain(0);
            updateAction();
        }else{
            setMaintain(1);
            updateAction();
        }
                }
    
    const state = {
        file: null,
        base64URL: ""
      };
    
      const handleFileInputChangeLogo = (fileInput) => {
       if (fileInput) {
            // Size Filter Bytes
            const max_size = 20971520;
      
            if (fileInput.size > max_size) {
                toast.error('Maximum size allowed is ' + max_size / 1000 + 'Mb') 
            }
    
            let reader = new FileReader();
            reader.readAsDataURL(fileInput);
            reader.onload = function () {
    
                setLogo(reader.result)
                setLogoUrl(reader.result)
    
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };        
        }    
      }

      const updateAction = async() =>{
        let url_ = 'settings/update/website';
        setLoading(true)
        let x = await requestHandler.post(url_, {title: title, sub_title: subTitle, about: about, logo: logoUrl, email: email, phone1: phone1, phone2: phone2, url: url, address: officeAddress}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
      }

      const websiteSettings = async () => {
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            x = x.message;

            //setMaintain(x.maintain)
                    setTitle(x.title)
                    setSubTitle(x.sub_title)
                    setLogoUrl(x.logo)
                    setUrl(x.url)
                    setAbout(x.about)
                    setPhone1(x.phone1)
                    setPhone2(x.phone2)
                    setEmail(x.email)
                    setOfficeAddress(x.office_address)
                    setMaintain(x.under_maintenance)
        }
    }

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
      }

    useEffect(() => {
        document.title = pageTile
        websiteSettings()
    },[pageTile])

    return(
        <div className="col">
            <h2 class="small-title">App Information</h2>
         <div class="row">					
                  

                  <div class="col-xl-12 col-12">
                      <div class="box">
                         <div class="box-body pt-0">
    
                                
                                {/*maintain == 1? <div class="input-group mb-3">
                                <button class="btn btn-success btn-sm"> MAINTENANCE IS ON </button> 
                                <button onClick={() => putToMaintenance()} class="btn btn-danger btn-sm"> CLICK TO OFF </button> 
                                </div>
                                :
                                <div class="input-group mb-3">
                                <button class="btn btn-danger btn-sm">MAINTENANCE IS OFF </button> 
                                <button onClick={() => putToMaintenance()} class="btn btn-success btn-sm"> CLICK TO ON </button> 
                                </div>
                                */}
    
                          <div class="table-responsive">
                                <table class="table mb-0 ">
                                    <tbody>
                                    <tr>
                                            <th>Website Title</th>
                                            <td>
                                                <input 
                                        onChange={e => setTitle(e.target.value)} 
                                        value={title}
                                        class="form-control" 
                                        type="text"/>
                                        </td>
                                        </tr>
                                        <tr>
                                            <th>Website Sub-Title</th>
                                            <td><input className='form-control' value={subTitle} 
                                             onChange={e => setSubTitle(e.target.value)} /></td>
                                        </tr>
                                        <tr>
                                            <th>About</th>
                                            <td><textarea rows="3" className='form-control' defaultValue={about} onChange={e => setAbout(e.target.value)}>
                                                {about}
                                                </textarea> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Office Address</th>
                                            <td><input className='form-control' value={officeAddress} 
                                             onChange={e => setOfficeAddress(e.target.value)} /></td>
                                        </tr>
                                        <tr>
                                            <th>App Logo</th>
                                            <td>
                                            <div style={{display: logoUrl !== null && logoUrl !== ""? `block` : `none`}} class="sw-13 position-relative mb-3">
    <img src={logoUrl} className="img-fluid rounded-xl text-center"/>
    </div>
                                                <input className='form-control' type={"file"} accept="image/*" 
                                             onChange={e => handleFileInputChangeLogo(e.target.files[0])} /></td>
                                        </tr>
                                        <tr>
                                            <th>Website Link</th>
                                            <td><input className='form-control' value={url} 
                                             onChange={e => setUrl(e.target.value)} /></td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td><input className='form-control' 
                                            type={"text"} 
                                            value={email} 
                                             onChange={e => setEmail(e.target.value)} /></td>
                                        </tr>
                                        <tr>
                                            <th>Line 1</th>
                                            <td><input className='form-control' 
                                            type={"tel"} 
                                            value={phone1} 
                                             onChange={e => setPhone1(e.target.value)} /></td>
                                        </tr>
                                        <tr>
                                            <th>Line 2</th>
                                            <td><input className='form-control' 
                                            type={"tel"} 
                                            value={phone2} 
                                             onChange={e => setPhone2(e.target.value)} /></td>
                                        </tr>
                                        
                                    </tbody>
                                    </table>
                                    <div class="col-12 mt-3">  
    
                                     <div class="col-xl-12 col-12">
                      
                  </div>
    
                                    {isLoading? <Loading /> :
                                    <button class="btn btn-primary" onClick={() => updateAction()} type="button">
                                    Update
                                </button>
                                }
                                </div>
                                    </div>
                             </div>
                      </div>
                  </div>				
                        
                              </div>

        </div>  
    )
}

export default AppInfo;