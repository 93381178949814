import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"

const NewAdmin = () => {

    const [pageTile, setPageTitle] = useState("New Admin")
    
    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
        
        </div>  
    )
}

export default NewAdmin;