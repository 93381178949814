import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import images from "../../utilities/images"
import moneyFormat from "../../utilities/moneyFormat"

const TopEarners = () => { 

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Top Earners")
    const [coupon, setCoupon] = useState(null);
    const [officialEmail, setOfficialEmail] = useState(null)
    const disclaimer = "Kindly note that Vendors are like other users and they are not affiliated to us, but given the task of selling coupon codes. Kindly report any vendor that you are not satisfied with. If you want to become a vendor, kindly write to us.";
    const [isLoading, setLoading] = useState(false)
    const [earners, setEarners] = useState([])

    const getTopEarners = async () => {
        setLoading(true)

        let x = await requestHandler.get('user/top-earners', false, false)
        if(x != null && x.status === 1){
            setEarners(x.message)
            setLoading(false)
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
            setLoading(false)
        }
        else{
            toast.error('Something went wrong')
            setLoading(false)
        }
        
    }

    useEffect(() => {
        getTopEarners()
        document.title = pageTile
    },[pageTile])


    return(
     
      <div class="row justify-content-center align-items-center">
                <div class="col-md-12 col-lg-12 col-xl-12">  
                <div className="card">
                <div className="card-body">
                    <h1>{pageTile}</h1>
                
                    <div className="row g-2">

                        {
                        earners.map((x, index) => <div class="col-12 col-lg-6">
                      <div class="card">
                      <a href={'#'} rel="noreferrer" class="row g-0 sh-11">
                          <div class="col-auto h-100">
                            <img src={x.picture !== null && x.picture !== ""? x.picture : images.logo} alt="user" class="card-img card-img-horizontal sw-11 sw-md-14 theme-filter" />
                            <div class="position-absolute bg-foreground opacity-75 text-primary px-1 py-1 text-extra-small b-2 s-2 rounded-lg">{1+index}</div>
                          </div>
                          <div class="col">
                            <div class="card-body d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                              <div class="d-flex flex-column">
                                <div class="font-heading">{x.username}</div>
                                <div class="text-uppercase text-muted text-truncate">{moneyFormat(x.deposited, 'Nigeria')}</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    )}
                    
                        </div>

                    </div>
                    </div>
                </div>
                </div>
    )
}

export default TopEarners;