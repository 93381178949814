import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requestHandler from "../utilities/requestHandler";
import images from "../utilities/images";

const Header = () => {

  const [loggedIn, setLoggedIn] = useState(false)
  const [userData, setUserData] = useState([])
  const [logoUrl, setLogoUrl] = useState(null);

  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x !== null && x.status === 1){
        let y = x.message;
        setLoggedIn(true)
    }
  }

const userProfile = async () => {
  let x = await requestHandler.get('user/profile', true);
  if(x != null && x.status === 1){
      let y = x.message;
      setUserData(y[0])
      
  }
}

const websiteSettings = async () => {
  let x = await requestHandler.get('settings/get', false);
  if(x != null && x.status === 1){
      let y = x.message;
      setLogoUrl(x.message.logo)
  }
}


useEffect(()=>{
  websiteSettings()
  checkIfLoggedIn()
userProfile()
},[]);

    return (
        <div id="nav" class="nav-container d-flex">
        <div class="nav-content d-flex">

          <div class="logo position-relative">
            <a href="/">

            <div style={{display: logoUrl !== null && logoUrl !== ""? `block` : `none`}} class="sw-13 position-relative">
    <img src={logoUrl} className="img-fluid rounded-xl" style={{maxWidth: 75, maxHeight: 35}}/>
    </div>
            </a>
          </div>
          <div class="user-container d-flex">
            <div style={{display: loggedIn? 'flex' : 'none'}}>
            <a href="#" class="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="profile" alt="profile" src={userData.picture !== null? userData.picture :  images.profileImage} />
              <div class="name">{userData.first_name}</div>
            </a>
            <div class="dropdown-menu dropdown-menu-end user-menu wide">
              
              <div class="row mb-1 ms-0 me-0">
                <div class="col-6 ps-1 pe-1">
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/user/profile">
                        <i data-cs-icon="user" class="me-2" data-cs-size="17"></i>
                        <span class="align-middle">Profile</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="col-6 pe-1 ps-1">
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/auth/logout">
                        <i data-cs-icon="logout" class="me-2" data-cs-size="17"></i>
                        <span class="align-middle">Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>

          <ul class="list-unstyled list-inline text-center menu-icons">
            {/*<li class="list-inline-item">
              <a href="#" data-bs-toggle="modal" data-bs-target="#searchPagesModal">
                <i data-cs-icon="search" data-cs-size="18"></i>
              </a>
            </li>*/}
            <li class="list-inline-item">
              <a href="#" id="colorButton">
                <i data-cs-icon="light-on" class="light" data-cs-size="18"></i>
                <i data-cs-icon="light-off" class="dark" data-cs-size="18"></i>
              </a>
            </li>
            <li class="list-inline-item">
          <Link to={"/auth/logout"} style={{display: loggedIn? 'flex' : 'none'}}>
          <i data-cs-icon="power" data-cs-size="18"></i>
            </Link>
            </li>
            <li class="list-inline-item">
          <Link to={"/auth/login"} style={{display: !loggedIn? 'flex' : 'none'}}>
          <i data-cs-icon="login" data-cs-size="18"></i>
            </Link>
            </li>

          </ul>

          <div class="menu-container flex-grow-1">
            <ul id="menu" class="menu">
            <li>
                <a href="/home">
                  <i data-cs-icon="home" class="icon" data-cs-size="18"></i>
                  <span class="label">Home</span>
                </a>
          </li>
            {/* <li>
                <a href="/about">
                  <i data-cs-icon="question-circle" class="icon" data-cs-size="18"></i>
                  <span class="label">About</span>
                </a>
          </li>
              <li>
                <a href="/terms">
                  <i data-cs-icon="list" class="icon" data-cs-size="18"></i>
                  <span class="label">Terms</span>
                </a>
          </li> */}
          <li>
                <a href="/services">
                  <i data-cs-icon="list" class="icon" data-cs-size="18"></i>
                  <span class="label">Our Services</span>
                </a>
              </li>

              <li>
                    <a href="#coupon" data-href="/auth/coupon">
                    <i data-cs-icon="server" class="icon" data-cs-size="18"></i>
                      <span class="label">Coupon</span>
                    </a>
          <ul id="coupon">
                      <li>
                        <a href="/auth/coupon">
                          <span class="label">Buy Coupon</span>
                        </a>
                      </li>
                      <li>
                        <a href="/auth/coupon/verify">
                          <span class="label">Verify Coupon</span>
                        </a>
                      </li>
                    </ul>
                    </li>

             {/* <li>
                <a href="/auth/coupon">
                  <i data-cs-icon="server" class="icon" data-cs-size="18"></i>
                  <span class="label">Buy Coupon</span>
                </a>
              </li>
              <li>
                <a href="/auth/coupon/verify">
                  <i data-cs-icon="check-circle" class="icon" data-cs-size="18"></i>
                  <span class="label">Verify Coupon</span>
                </a>
              </li> */}
              <li>
                <a href="/home/contents">
                  <i data-cs-icon="share" class="icon" data-cs-size="18"></i>
                  <span class="label">Viral Posts</span>
                </a>
              </li>
              <li>
                <a href="/home/market-place" style={{display: !loggedIn? 'flex' : 'none'}}>
                  <i data-cs-icon="cart" class="icon" data-cs-size="18"></i>
                  <span class="label">Market Place</span>
                </a>
              </li> 
              <li>
                <a href="/home/top-earners">
                  <i data-cs-icon="chart-2" class="icon" data-cs-size="18"></i>
                  <span class="label">Top Earners</span>
                </a>
              </li>
              {/* !loggedIn? <li>
                <Link to="/auth/login" style={{display: !loggedIn? 'flex' : 'none'}}>
                  <i data-cs-icon="login" class="icon" data-cs-size="18"></i>
                  <span class="label">Log In</span>
                </Link>
              </li> : '' */}
              {/* !loggedIn? <li>
                <Link to="/auth/register">
                  <i data-cs-icon="user-plus" class="icon" data-cs-size="18"></i>
                  <span class="label">Register</span>
                </Link>
              </li> 
              : 
              ''*/}

<li>
                    <a href="#other_links" data-href="Pages.Authentication.html">
                    <i data-cs-icon="link" class="icon" data-cs-size="18"></i>
                      <span class="label">Other Links</span>
                    </a>
          <ul id="other_links">
                      <li>
                        <a href="/about">
                          <span class="label">About</span>
                        </a>
                      </li>
                      <li>
                        <a href="/terms">
                          <span class="label">Terms</span>
                        </a>
                      </li>
                      <li>
                        <a href="/how-it-works">
                          <span class="label">How It Works</span>
                        </a>
                      </li>
                      <li>
                        <a href="/package-limitations">
                          <span class="label">Package Limitations</span>
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <span class="label">Contact Us</span>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <span class="label">Dashboard</span>
                        </a>
                      </li>
                    </ul>
                    </li>
          
              
            </ul>
          </div>

          <div class="mobile-buttons-container">
            <a href="#" id="mobileMenuButton" class="menu-button">
              <i data-cs-icon="menu"></i>
            </a>
          </div>
        </div>
        <div class="nav-shadow"></div>
      </div>
    )
}
export default Header;