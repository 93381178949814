import { useEffect, useState } from "react"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import images from "../../utilities/images"

const ContactUs = () => {

    const [pageTile, setPageTitle] = useState("Contact Us")
    const [about, setAbout] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [maintain, setMaintain] = useState(null);
    const [title, setTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [url, setUrl] = useState(null);
    const [phone1, setPhone1] = useState(null);
    const [phone2, setPhone2] = useState(null);
    const [email, setEmail] = useState(null);
    const [officeAddress, setOfficeAddress] = useState(null);

    const websiteSettings = async () => {
        setLoading(true)
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            x = x.message;
            setTitle(x.title)
                    setSubTitle(x.sub_title)
                    setLogoUrl(x.logo)
                    setUrl(x.url)
                    setAbout(x.about)
                    setPhone1(x.phone1)
                    setPhone2(x.phone2)
                    setEmail(x.email)
                    setOfficeAddress(x.office_address)
                    setMaintain(x.under_maintenance)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        document.title = pageTile
       // websiteSettings()
    },[pageTile])

    const whatsappNums = [
      {id: 1, name: "Michael", phone: "2348138292521"},
      {id: 2, name: "Bolu", phone: "2349113768762"},
      {id: 3, name: "Sam", phone: "2348175059392"},
    ]

    return(
        <div className="col">
          <h2 class="small-title">{pageTile}</h2>
          {isLoading? <Loading/> : ''}
<div class="row g-2">
<div class="col-md-6 col-lg-6 col-xl-6"> 
<div class="card mb-5">
                <div class="card-body">
                  <div class="d-flex align-items-start flex-column mb-4">
                    <div class="d-flex align-items-start flex-column">
                      <div class="h5 mb-0">
                      Do you need help or inquiry?<br/>
Chat with us any day, any time 
We are ready to serve you better 
</div>
                      <div class="text-start text-muted">
                      Whatapps customers care 24/7<br/>
Monday to Saturday
                        </div>
              
                    <div className="row g-2">

                        {
                        whatsappNums.map(x => <div class="col-12 col-lg-12">
                      <div class="card">
                      <a href={'https://wa.me/'+x.phone} target="_blank" rel="noreferrer" class="row g-0 sh-11">
                          <div class="col-auto h-100">
                            <img src={images.logo} alt="user" class="card-img sw-11 sw-md-14 theme-filter" />
                            <div class="position-absolute bg-foreground opacity-75 text-primary px-1 py-1 text-extra-small b-2 s-2 rounded-lg">WhatsApp</div>
                          </div>
                          <div class="col">
                            <div class="card-body d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                              <div class="d-flex flex-column">
                                <div class="font-heading">{x.name}</div>
                                <div class="text-uppercase text-muted">+{x.phone}</div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    )}
                    
                        </div>

                        <div class="text-start text-muted">
                        Calling line  ( 9am to 6pm) Monday to Saturday 
                        </div>
                        <div class="nav flex-column" role="tablist">
                        <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href={"tel:08138292521"} role="tab">
                    <i className="fa-regular fa-phone me-2" data-cs-size="17"></i>
                      <span class="align-middle">08138292521</span>
                    </a>
                    
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href={"mailto:info@themainceos.com"} role="tab">
                    <i className="fa-regular fa-envelope me-2" data-cs-size="17"></i>
                      <span class="align-middle">info@themainceos.com</span>
                    </a>
                        </div>

                    </div>
                  </div>
                    
                    
                </div>
              </div>
</div>

<div class="col-md-6 col-lg-6 col-xl-6">
<img src={images.logo}
            class="img-fluid" alt="Sample image" />
   </div>


</div>

        </div>  
    )
}

export default ContactUs;