import { useEffect, useState } from "react";
import requestHandler from "../../utilities/requestHandler";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loading from "../../utilities/Loading";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import RecentProducts from "./recentProducts";
import UpdateProduct from "./updateProduct";
import { RWebShare } from "react-web-share";
import truncate from "../../utilities/truncateString";
//import urls from "../../utilities/urls";
import Helmet from 'react-helmet';
import dateTimeToDate from "../../utilities/dateTimeToString";
import RandomString from "../../utilities/randomString";
import ProductDetails from "./productDetails";
import urls from "../../utilities/urls";
import moneyFormat from "../../utilities/moneyFormat";
import FlutterWaveGateway from "../flutterwaveGateway";
 
const ViewProduct = ({route}) => {

  const [pageTile, setPageTitle] = useState("Product")
  const [shareUrl, setShareUrl] = useState(null)
    const [newUniqueId, setNewUniqueId] = useState(null)
  const {uniqueId} = useParams()
    const {userId} = useParams()
    const [isLoading, setLoading] = useState(false)
    const [post, setPost] = useState([])
    const [postViewed, setPostViewed] = useState(false)
    const [editPost, setEditPost] = useState(false)
    const account_type = localStorage.getItem("account_type")
    const [userData, setUserData] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [sessionId, setSessionId] = useState(null)
    const [productType, setProductType] = useState('')
    const [postId, setPostId] = useState('')
    const [popUp, setPopUp] = useState(false)
    const [paymentModePopUp, setPaymentModePopUp] = useState(false)
    const [amount, setAmount] = useState(0)

    const closePopUp = () => {
      setPopUp(false)
  }

  const PopUp = () => {
    return( popUp?
        <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
             <div class="fixed-background"></div>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0 p-0">
        <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={closePopUp}></button>
      </div>
      <div class="modal-body ps-5 pe-5 pb-0 border-0">
        <h4>{pageTile}</h4>
      </div>
      <div class="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                <div class="justify-content-start">
                    <div style={{whiteSpace: "pre-wrap"}} class="text-normal">Kindly wait while we prepare the download of your just purchased digital content</div>
          </div>
        </div>

      </div>
    </div>
  </div>
  : 
  ''
   ) 
  
}

const PaymentModePopUp = () => {
  return( paymentModePopUp?
      <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
           <div class="fixed-background"></div>
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header border-0 p-0">
      <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setPaymentModePopUp(false)}></button>
    </div>
    <div class="modal-body ps-5 pe-5 pb-0 border-0">
      <h4>{'Mode of Payment'}</h4>
    </div> 
    <div class="modal-body border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
              <div class="justify-content-start">
                  <div style={{whiteSpace: "pre-wrap"}} class="text-normal">
                  <div className="mb-2">
                    Kindly select a mode of payment to purchase {pageTile}
                    </div>
                    <div className="mb-2">
                      <button onClick={() => purchaseProduct(postId)} type="button" class="btn btn-primary">Purchase With Balance</button>
                      </div>
                    <div>
                      <a target="_blank" href="/vtu/user/fund_wallet" class="btn btn-success">Fund Wallet</a>
                      {/*<FlutterWaveGateway 
                      productType={productType}
                      amount={amount} 
                      name={userData.username}
                      email={userData.email}
                      phone={userData}
                       />*/}
                       </div>
                    </div>
        </div>
      </div>

    </div>
  </div>
</div>
: 
''
 ) 

}



    const userProfile = async () => {
      setLoading(true)
      let x = await requestHandler.get('user/profile', true);
      if(x != null && x.status === 1)
      {
          let y = x.message;
          setUserData(y[0])
          setLinkToShare(y[0].user_id, uniqueId)
          
      }
      else {
        setLinkToShare(null, uniqueId)
      }
      
      //get post
      //alert("hey")
      getProducts()

      setLoading(false)
    }

    const getProducts = async () => {
      setLoading(true)
      //alert(sessionId)
        //if(sessionId != null){
        let curUrl = 'product/'+userId+'/'+uniqueId+sessionId+'/view'
        if(userId === undefined){
          curUrl = 'product/'+uniqueId+'/view'
        }
        
        let x = await requestHandler.get(curUrl, true);
        if(x != null && x.status === 1)
        {
            setPost(x.message)
            setNewUniqueId(x.message.unique_id)
            setPageTitle(x.message.post_title)
            setAmount(x.message.amount)
            setProductType(x.message.product_type)
            setPostId(x.message.id)
            setPostViewed(true)
            console.log(x.message)
        }
        else
        {
            toast.error(x.message)
        }
      
      
      //}
        setLoading(false)
      }
    
      const deleteBtn = async (id) => {
        let canDelete = window.confirm("Do you want to delete post?")
        if(canDelete){

        setLoading(true)
        let x = await requestHandler.get('product/'+id+'/delete', true);
        if(x != null && x.status === 1){
            toast.success(x.message)
            window.location.href="/product/all"
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
    }

      }

      const sleep = ms => new Promise(r => setTimeout(r, ms));

      const purchaseProduct = async (id) =>{
        setLoading(true)
        
        let curUrl = 'product/purchase/wallet'
        let canPurchase = window.confirm("Do you want to proceed with the purchase of "+pageTile+"?")
        if(canPurchase){
        
        let x = await requestHandler.post(curUrl, {product_id: id}, false, true);
        if(x != null && x.status === 1){
            toast.success(x.message.message)
            setPopUp(true)
            await sleep(5000);
            //download file
            if(productType == "Downloadable"){
              window.location.href=x.message.link
            }
            else if(productType == "Link"){
              window.location.href=x.message.link
            }
            
        }
        else{
            toast.error(x.message)
        }
      }
        setLoading(false)
      }

      const ShareBtn = () => {

        return <div>
                      <button 
                      onClick={() => setPaymentModePopUp(true) }
                      class="btn btn-sm btn-icon btn-outline-primary ms-1" type="button">
                      Purchase <i class="fa-regular fa-shopping-cart text-center"></i>
                      </button>
                      </div>
      }

      
      const setLinkToShare = (user_id, post_uid) =>{
        if(user_id !== null){
          const shareUrl_ = urls.appUrl+"product/"+user_id+"/"+post_uid+"/view"
            setShareUrl(shareUrl_)
            }
          else setShareUrl(urls.appUrl+"product/"+post_uid+"/view")
      }

      const setSession = () => {
        //set user unique session
        let randStr = RandomString(15)
        let session_Id = localStorage.getItem('sessionId')
        if(session_Id == null){
          setSessionId(randStr)
          localStorage.setItem('sessionId', randStr)
        }
      }

    useEffect(() => {      
      setSession()
      userProfile()
      document.title = pageTile
        //setLinkToShare()
    },[uniqueId])

    

    const Product = () => {
       return <div class="card mb-5">
        <Helmet>
                <title>{pageTile}</title>
                <meta name={pageTile} 
                product={pageTile} />
            </Helmet>

        <div class="card-body p-0">
                  <div class="glide glide-gallery" id="glideBlogDetail">
                    <div class="glide glide-large">
                    { post.post_video === null?
                      <img src={post.post_image} class="card-img-top sh-19" alt="card image" />
                  :
                    <video src={post.post_video} className="responsive border-0 rounded-top-end rounded-top-start img-fluid mb-3 w-100" alt="video" controls autoPlay loop></video>
                  }     
                    <div style={{position: 'fixed', zIndex: 500, bottom: 100, padding: 10, right: 10, top: "50%"}} class="text-muted d-lg-none">
                    <div class="row">
                    <ShareBtn />
                    </div>
                  </div>

                    </div>
                    
                  </div>
                  <div class="card-body pt-0">

                    <h4 class="mt-3"> 
                      <b>{moneyFormat(post.amount, 'Nigeria') }</b> - {post.post_title}</h4>
                    <p className="text-muted">
                              {dateTimeToDate(post.post_date)} | by {post.username}
                            </p>
                    <div style={{whiteSpace: "pre-wrap"}}>
                            {post.post_content}                            
                    </div>

                  </div>
                </div>
                <div class="card-footer border-0 pt-0">
                <div class="row align-items-center">

                  <div class="col-8 text-muted">
                    <div class="row g-0">
                    <div class="col-auto pe-3">
                          <span class="align-middle"> <i class="fa-regular fa-eye m-1"></i>  {number_format(post.clicks)}</span>
                          </div>
                          {/*<div class="col">
                            <span class="align-middle">  <i class="fa-regular fa-share m-1"></i>{number_format(post.shares)} </span>
                </div>*/}
                    </div>
                  </div>

                  
                  <div class="col-4 d-none d-lg-block">
                    <div class="d-flex align-items-center justify-product-end ">
                    <ShareBtn />
                    </div>
                  </div>

                </div>
              </div>
            </div>
    }


    return(
        <div className="col">

<div class="row">
            <div class="col-12 col-xl-8 col-xxl-9 mb-5">
                {isLoading? <Loading/> : ''}
                <div style={{display: userData.account_type === "Super Admin" && !editPost? 'block' : 'none'}}>
                  <div class="col-12 col-md-12 d-flex align-items-start justify-product-start m-1">
                {/*<button onClick={() => editPost? setEditPost(false) : setEditPost(true) } type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto m-1">
                  <i data-cs-icon="edit-square"></i>
                  <span>Edit</span>
                </button>*/}
                <button onClick={() => deleteBtn(post.id)} type="button" class="btn btn-danger m-1">Delete</button>
                {/* <button onClick={() => {showDetails? setShowDetails(false) : setShowDetails(true)}} type="button" class="btn btn-success m-1">{showDetails? 'Hide Overview' : 'Video Overview'}</button>*/}
              </div>
              </div>

                {editPost? <UpdateProduct
                id={post.id}
                unique_id={uniqueId}
                post_title={post.post_title}
                post_video={post.post_video}
                post_image={post.post_image}
                post_product={post.post_product}
                status={post.status}
                duration={post.duration}
                size={post.size}
                post_date={post.post_date}
                /> : showDetails? <ProductDetails id={post.id} /> : <Product/> }

            </div>

            <RecentProducts />

            </div>
            <PopUp />
            <PaymentModePopUp />
        </div>  
    )
}

export default ViewProduct;