import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";
import moneyFormat from "../../utilities/moneyFormat";

const PendingWithdrawals = () => {

    const [pageTile, setPageTitle] = useState("Transactions")
    
    const [isLoading, setLoading] = useState(false)
    const [tx, setTx] = useState([])
    const [walletType, setWalletType] = useState(null)
    const [amount, setAmount] = useState(null)
    const [country, setCountry] = useState(null)

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        userProfile()
        setLoading(false)
      }

      const userProfile = async () => {
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            setCountry(y.country)
        }
      }

      const getTransactions = async () => {
        setLoading(true)
        let x = await requestHandler.get('admin/withdrawals/pending', true);
        if(x != null && x.status === 1){
            setTx(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const withdrawAction = async () =>{
        let url = 'admin/payment/{txId}/{x}';
        setLoading(true)
        let x = await requestHandler.post(url, {wallet_type: walletType, amount: amount}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
                getTransactions()
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

    const paymentApproval = async (x, y) =>{
        let txt = "Are you sure you want to "+y+"?";
        if(y === "decline") txt = "Are you sure you want to decline payment?"
        let canDelete = window.confirm(txt)
        let url = 'admin/payment/'+x+'/'+y;
        if(canDelete){
          setLoading(true)
          let role = "Vendor"
          let x = await requestHandler.get(url, true)
              if(x != null && x.status === 1){
                  toast.success(x.message)
                  getTransactions()
              }
              else if(x != null && x.status === 0){
                  toast.error(x.message)
              }
              else{
                  toast.error('Something went wrong')
              }
        }
        
        setLoading(false)
    }
  

      
    useEffect(() => {
        checkIfLoggedIn()
        getTransactions()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
             <h2 class="small-title">Pending Withdrawals</h2>
           
            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

                <table className="table table-striped">
        <thead>
          <tr>
          <th>Date</th>
          <th>Amount</th> 
          <th>Type</th>
          <th>Status</th>
          </tr>
          </thead>
          <tbody>
      {tx.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}
          <br/><b>Bank</b>: {x.bank_name}
          </td>
          <td>{moneyFormat(x.amount, 'Nigeria')}
          <br/><b>Name</b>: {x.account_name}
          </td>
          <td>{x.type}
          <br/><b>Account No</b>: {x.account_number}
          </td>
          <td>{x.status == 0? 'Pending' : x.status == 1? 'Successful' : 'Rejected'}
          <br/>
          <button style={{display: x.status == 1? 'flex' : 'none' }} onClick={() => paymentApproval(x.id, 'decline')} type="button" class="btn btn-success m-1">Decline Payment Vendor</button>
                <button style={{display: x.status == 0 || x.status == 2? 'flex' : 'none' }} onClick={() => paymentApproval(x.id, 'approve')} type="button" class="btn btn-secondary m-1">Approve Payment</button>
          </td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default PendingWithdrawals;