import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requestHandler from "../utilities/requestHandler";

const LeftMenu = () =>{

  const [loggedIn, setLoggedIn] = useState(false)
  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [account_type, setAccountType] = useState(localStorage.getItem('account_type'));

  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x != null && x.status === 1){
        let y = x.message;
        setLoggedIn(true)
    }
}

useEffect(()=>{
checkIfLoggedIn()
},[]);

    return (

      <div class="col-auto d-none d-lg-flex">
          {account_type !== null? <ul class="sw-25 side-menu mb-0 primary" id="menuSide">
            <li>
              <Link to="/">
                <i data-cs-icon="home" class="icon" data-cs-size="18"></i>
                <span class="label">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/recent">
                <i data-cs-icon="news" class="icon" data-cs-size="18"></i>
                <span class="label">Recent</span>
              </Link>
            </li>
            <li>
              <Link to="/user/contents/shared">
                <i data-cs-icon="share" class="icon" data-cs-size="18"></i>
                <span class="label">Shared</span>
              </Link>
            </li>
            <li>
              <Link to="/user/referrals">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Referrals</span>
              </Link>
            </li>

{account_type === 'Admin' || account_type === 'Super Admin' ? <>
            <li>
              <a href="#" data-bs-target="#admin">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Admin</span>
              </a>
              <ul>
                <li>
                  <Link to="/admin/users">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Users</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/users/top">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Top Users</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/list">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Admin</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/app/info">
                    <i data-cs-icon="square" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">App Info</span>
                  </Link>
                </li>
              </ul>
            </li>
            </> :
            ''}


<li>
              <a href="#" data-bs-target="#vtu">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">VTU</span>
              </a>
              <ul>
                    
              <li>
              <Link to="/vtu/user/dashboard">
                <i data-cs-icon="dashboard-2" class="icon" data-cs-size="18"></i>
                <span class="label">VTU Dashboard</span>
              </Link>
            </li>
                    
                    <li>
                    <a href="#airtime" data-bs-target="#vtu/">
                <i data-cs-icon="mobile" class="icon" data-cs-size="18"></i>
                <span class="label">Airtime and Data</span>
              </a>				
                      <ul class="airtime">					
                      <li><Link  to="/vtu/user/buy_airtime"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Buy Airtime</Link></li>
                      <li><Link  to="/vtu/user/print_airtime"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Print Airtime</Link></li>
                      <li><Link  to="/vtu/user/none_sme_data"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Regular Data</Link></li>
                        <li><Link  to="/vtu/user/mtn_special"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>SME Data</Link></li>
{/*                        <li><Link  to="/vtu/user/foreign_airtime"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Foreign Airtime</Link></li> */}
                      </ul>
                    </li>
                    
                    {/*<li>
                    <Link to="/vtu/user/send_money">
                <i data-cs-icon="send" class="icon" data-cs-size="18"></i>
                <span class="label">Send Money</span>
              </Link>
                      </li>*/}
                   	 
                   	 
                      <li>
                      <Link  to="/vtu/user/cable_tv">
                      <i data-cs-icon="tv" class="icon" data-cs-size="18"></i>
                        <i data-feather="tv"></i>
                        <span>Cable TV</span>
                      </Link>
                      </li>                   	 
                   	 
                      <li>
                      <Link  to="/vtu/user/electricity">
                      <i data-cs-icon="light-on" class="icon" data-cs-size="18"></i>
                        <span>Electricity Bill</span>
                      </Link>
                      </li>

                      <li>
                      <a href="#wallet" data-bs-target="#vtu/">
                <i data-cs-icon="wallet" class="icon" data-cs-size="18"></i>
                <span class="label">Wallet</span>
              </a>		
                      <ul id="wallet" class="menu">					
                      <li><Link  to="/vtu/user/fund_wallet"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Add fund</Link></li>
                      {/*<li><Link  to="/vtu/user/wallet"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Balances</Link></li>*/}
                      <li><Link  to="/vtu/user/transactions"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Transactions</Link></li>
                        <li><Link  to="/vtu/user/cashback/transactions"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Cashback history</Link></li>
                        {/*<li><Link  to="/vtu/wallet/user/load_voucher"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Load voucher for wallet</Link></li>*/}
                        {/*<li><Link  to="/vtu/user/transfer"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Transfer</Link></li>*/}
                        <li><Link  to="/vtu/user/withdraw"><i class="icon-Commit"><span class="path1"></span><span class="path2"></span></i>Withdraw</Link></li>
                      </ul>
                    </li>
                   	 
                      <li>
                      <Link  to="/vtu/user/transactions">
                      <i data-cs-icon="print" class="icon" data-cs-size="18"></i>
                        <span>Print Receipt</span>
                      </Link>
                      </li>

                      {/* <li>
                      <Link  to="/user/order_printer">
                        <i data-feather="printer"></i>
                        <span>Order Airtime Printer</span>
                      </Link>
                      </li> */}

                </ul>
                </li>

{account_type === 'Author' || account_type === 'Admin' || account_type === 'Super Admin' ? <>
            <li>
              <a href="#" data-bs-target="#content">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Content</span>
              </a>
              <ul>
              <li>
                  <Link to="/content/all">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Contents</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/new">
                    <i data-cs-icon="plus" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Create New</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/content/filter">
                    <i data-cs-icon="share" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Users Share Reports</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/content/filter/all">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Contents Report</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/pending">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Pending</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/shared">
                    <i data-cs-icon="share" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Shared</span>
                  </Link>
                </li>
                <li>
                  <Link to="/content/not-shared">
                    <i data-cs-icon="info-hexagon" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Not Shared</span>
                  </Link>
                </li>
              </ul>
            </li>
            </>
            :
            '' }


{account_type === 'Author' || account_type === 'Admin' || account_type === 'Super Admin' ? <>
            <li>
              <a href="#" data-bs-target="#content">
                <i data-cs-icon="grid-1" class="icon" data-cs-size="18"></i>
                <span class="label">Market Place</span>
              </a>
              <ul>
              <li>
                  <Link to="/product/all">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Products</span>
                  </Link>
                </li>
                <li>
                  <Link to="/product/new">
                    <i data-cs-icon="plus" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Create New</span>
                  </Link>
                </li>
                <li>
                  <Link to="/product/pending">
                    <i data-cs-icon="calendar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Pending</span>
                  </Link>
                </li>
              </ul>
            </li>
            </>
            :
            '' }

            <li>
              <a href="#" data-bs-target="#vendor">
                <i data-cs-icon="server" class="icon" data-cs-size="18"></i>
                <span class="label">Coupons</span>
              </a>
              <ul>
              <li>
                  <Link to="/vendor/coupons">
                    <i data-cs-icon="notebook-1" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">All Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/coupons/used">
                    <i data-cs-icon="chart-3" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Used Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/coupons/unused">
                    <i data-cs-icon="chart-2" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Unused Coupons</span>
                  </Link>
                </li>
                <li>
                  <Link to="/vendor/coupons/search">
                    <i data-cs-icon="search" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Search Coupon</span>
                  </Link>
                </li>
                <li>
                  <a href="/auth/coupon/verify">
                    <i data-cs-icon="check-circle" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Verify Coupon</span>
                  </a>
                </li>
                <li>
                  <a href="/auth/coupon">
                    <i data-cs-icon="cart" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Buy Coupon</span>
                  </a>
                </li>
              </ul>
            </li>


            <li>
              <a href="#" data-bs-target="#profile">
                <i data-cs-icon="user" class="icon" data-cs-size="18"></i>
                <span class="label">Account</span>
              </a>
              <ul>
              <li>
                  <Link to="/user/profile">
                    <i data-cs-icon="user" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Profile</span>
                  </Link>
                </li>
                <li>
                               <Link  to="/vtu/user/update_pin">
                               <i data-cs-icon="lock" class="icon d-none" data-cs-size="18"></i>
                                <span class="label">Change PIN</span>
                                </Link>
                               </li>	
                {/*<li>
                  <Link to="/user/withdraw">
                    <i data-cs-icon="dollar" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Withdraw</span>
                  </Link>
                </li>*/}
                <li>
                  <Link to="/user/banks">
                    <i data-cs-icon="suitcase" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Bank/Mobile</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user/transactions">
                    <i data-cs-icon="list" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Transactions</span>
                  </Link>
                </li>
               {/* <li>
                  <Link to="/user/profile/overview">
                    <i data-cs-icon="file-chart" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Overview</span>
                  </Link>
              </li> */}
                <li>
                  <Link to="/user/profile/password/update">
                    <i data-cs-icon="shield" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Update Password</span>
                  </Link>
                </li>
                <li>
                  <a target="_blank" href="https://agent.themainceos.com/auth/login">
                    <i data-cs-icon="dashboard-2" class="icon d-none" data-cs-size="18"></i>
                    <span class="label">Agent Dashboard</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          :
          ''
}

        </div>
    )
}
export default LeftMenu;