
import { useEffect, useState } from "react";
import Loading from "../../utilities/Loading";
import requestHandler from "../../utilities/requestHandler";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import moneyFormat from "../../utilities/moneyFormat";
import truncate from "../../utilities/truncateString";
import PopularContent from "../landing/popularContent";
import PopularProducts from "../landing/popularProducts";

const MarketPlace = () => {
    const [pageTile, setPageTitle] = useState("Products")
    const [announcement, setAnnouncement] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [lastPopId, setLastPopId] = useState(localStorage.getItem('last_pop_id'))

    const [isLoading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])
    const [constPosts, setConstPosts] = useState(20)

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
      }

    const getContents = async () => {
        setLoading(true)
        let x = await requestHandler.get('product/20/list', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const getMoreContents = async () => {
        setLoading(true)
        let newConstPosts = constPosts + 20;
        setConstPosts(newConstPosts)
        let x = await requestHandler.get('product/'+newConstPosts+'/list', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }
    

    const closePopUp = () => {
        setPopUp(false)
    }
    useEffect(() => {
    document.title = pageTile
    getContents()
    },[pageTile])
    useEffect (()=>{
        //checkIfLoggedIn()
        //getAnnouncement()        
    },[announcement])

    const PopUp = () => {
        return(
            <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
                 <div class="fixed-background"></div>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={closePopUp}></button>
          </div>
          <div class="modal-body ps-5 pe-5 pb-0 border-0">
            <h4>{announcement.subject}</h4>
          </div>
          <div class="modal-footer border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                    <div class="justify-content-start">
                        <div style={{whiteSpace: "pre-wrap"}} class="text-normal">{announcement.announcement} </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        )
    }

    return (
        <div class="col">
           
            {/*popUp? <PopUp/> : ''*/}
            
            <div className="row">
            <div class="col-md-6 col-xl-8 col-xxl-9 mb-5">  

            <div className="col">
            {/*isLoading? <Loading/> : ''*/}
            <div class="row mb-5">
                {posts.map(x => 
                <div class="col-md-4">
                    <div className="m-1">
                  <div class="card h-100">
                    {x.post_image != null? <img src={x.post_image} class="card-img-top sh-19" alt="card image" />
                :
                <video src={x.post_video} className="card-img-top" alt="video"></video>    
                }
                    
                    <div class="card-body">
                      <h5 class="heading">
                        <a href={"/product/"+x.unique_id+"/view"} class="body-link ">
                          <span class="clamp-line h5 sh-5" data-line="2">{truncate(x.post_title, 45)} - {truncate(x.post_content, 25)}</span>
                        </a>
                      </h5>
                      <div>
                        <div class="row g-0">
                        <div class="col-auto pe-3">
                            <b>{moneyFormat(x.amount, 'Nigeria')}</b>
                          </div>
                          <div style={{display: x.post_video === null? 'none' : 'flex'}} class="col">
                          <i class="fa-regular fa-clock m-1"></i> 
                            <span class="align-middle"><TimeInWords time={x.duration} /> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                )}
                
        </div>

        {isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
        }
        </div>
                
            </div>
            <div class="col-md-1 col-xl-4 col-xxl-9 mb-5">
            <PopularProducts/>
            </div>
            </div>
            </div>
    )
}

export default MarketPlace;