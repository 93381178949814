import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import { toast } from "react-toastify"

const NewAnnouncement = () => {

    const [pageTile, setPageTitle] = useState("New Announcement")
    const [subject, setSubject] = useState(null)
    const [announcement, setAnnouncement] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
      }
    
      const sendContent = async () =>{
        //alert(duration)
        let url = 'admin/announcement/add';
        setLoading(true)
        let x = await requestHandler.post(url, {subject: subject, announcement: announcement}, false, true)
            if(x != null && x.status === 1)
            {
                //uploadImage(previewImage[0])
                toast.success(x.message)
                window.location.href="/admin/announcement/all"
            }
            else if(x != null && x.status === 0)
            {
                toast.error(x.message)
            }
            else
            {
                toast.error('Something went wrong')
            }
        setLoading(false)
    }

    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
        <h2 class="small-title">{pageTile}</h2>
              <div class="card mb-5">
                <div class="card-body">

                <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Subject</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <input type="text" class="form-control" onChange={e => setSubject(e.target.value)} value={subject} />
                      </div>
                    </div>
                    
                    <div class="mb-3 row">
                      <label class="col-lg-2 col-md-3 col-sm-4 col-form-label">Announcement</label>
                      <div class="col-sm-8 col-md-9 col-lg-10">
                        <textarea value={announcement} onChange={e => setAnnouncement(e.target.value)} class="form-control" rows="3"> </textarea>
                      </div>
                    </div>

                    <div class="mb-3 row mt-5">
                      <div class="col-sm-8 col-md-9 col-lg-10 ms-auto">
                      {isLoading? <Loading/> : 
                        <button style={{display: announcement !== null? 'flex' : 'none'}} onClick={sendContent} type="button" class="btn btn-primary">Post</button>
                       }                        
                      </div>
                    </div>

                    </div>
                </div>
        </div>  
    )
}

export default NewAnnouncement;