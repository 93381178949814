import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
const PopularContent = () =>{

  const [pageTile, setPageTitle] = useState("Popular Contents")
    const [isLoading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])

      const getContents = async () => {
        setLoading(true)
        let x = await requestHandler.get('content/popular', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        getContents()
        //document.title = pageTile
    },[pageTile])

    return (

        <div class="col-12 col-xxl-8 mb-5">
          <h2 class="small-title">{pageTile}</h2>

          <div class="row g-2">
            
          {posts.map(x => <div class="col-12 col-lg-12">
              <div class="card">
              <a href={"/content/"+x.unique_id+"/view"} class="row g-0 sh-11">
                  <div class="col-auto h-100">
                  {x.post_image != null? <img src={x.post_image} class="card-img card-img-horizontal sw-11 sw-md-14 theme-filter" alt="card image" />
                :
                <video src={x.post_video} className="card-img card-img-horizontal sw-11 sw-md-14 theme-filter" alt="video"></video>    
                }
                    <div style={{display: x.post_video === null? 'none' : 'flex'}} class="position-absolute bg-foreground opacity-75 text-primary px-1 py-1 text-extra-small b-2 s-2 rounded-lg"><TimeInWords time={x.duration} /></div>
                  </div>
                  <div class="col">
                    <div class="card-body d-flex flex-column pt-0 pb-0 h-100 justify-content-center">
                      <div class="d-flex flex-column">
                        <div class="font-heading">{truncate(x.post_title, 20)}</div>
                        <div class="text-small text-muted text-truncate">{truncate(x.post_content, 35)}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            )}

          </div>
        </div>

    )
}

export default PopularContent;