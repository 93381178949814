const mtn = require("../images/logo/mtn_logo.png");
const wallet = require("../images/logo/wallet.png");
const sms = require("../images/logo/sms.png");
const airtime = require("../images/logo/airtime.png");
const data = require("../images/logo/data.png");
const cable_tv = require("../images/logo/cable_tv.png");
const glo = require("../images/logo/glo.jpeg");
const airtel = require("../images/logo/airtel.jpg");
const nmobile = require("../images/logo/9mobile.jpg");
const electricity = require("../images/logo/electricity.png");
const print_airtime = require("../images/logo/printer.jpg");
const  whatsapp= require("../images/logo/whatsapp_logo.png");

export default {
    mtn, wallet, sms, airtime, data, cable_tv, glo, airtel, nmobile, electricity, print_airtime, whatsapp
}