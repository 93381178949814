import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import Loading from "../../utilities/Loading"
import countriesAndDialCodes from "../../utilities/countries"
import { toast } from "react-toastify"
import moneyFormat from "../../utilities/moneyFormat"

const FAQ = () => {

    const [pageTile, setPageTitle] = useState("FAQ")
    const [country, setCountry] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const faqs = [
        {id: 1, question: "Quest?", answer: "Ansewr"},
    ];

    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    const viewFaq = () =>{
        if(country === null || country === ""){
            toast.error("Select your country")
        }
    }

    return(
        <div className="col">
         <h2 class="small-title">Frequently Asked Questions</h2>

          {isLoading? <Loading/> : ''}
        
        <div class="row g-2">

              <div class="card">
              <div class="card-body">

              <div className="mb-3 filled">
                        <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%" id="">
                            <option>Select Country</option>
                          {
                            countriesAndDialCodes.map((x) => {
                            return (<option value={x.country}>{x.country}</option>)  
                            })
                          }
                        </select>
                      </div>

<div className="row g-2" style={{display: country === null || country === ""? 'none' : 'flex'}}>
                {faqs.map(x =>
                    <div className="mt-5">
              <h4>{x.question}</h4>
              <div style={{whiteSpace: "pre-wrap"}}>
              {x.answer} 
              </div>
              </div>
                )}
                </div>
                </div>

                </div>

</div>
        </div> 
    )
}

export default FAQ;