import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import requestHandler from "../../utilities/requestHandler"
import truncate from "../../utilities/truncateString"

const Announcements = () => {

    const [pageTile, setPageTitle] = useState("Announcements")
    const [isLoading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])

      const getContents = async () => {
        setLoading(true)
        let x = await requestHandler.get('admin/announcement/list', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const deleteBtn = async (id) => {
        let canDelete = window.confirm("Do you want to delete announcement?")
        if(canDelete){

        setLoading(true)
        let x = await requestHandler.get('admin/announcement/'+id+'/delete', true);
        if(x != null && x.status === 1){
            toast.success(x.message)
            getContents()
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
    }

      }

    useEffect(() => {
        document.title = pageTile
        getContents()
    },[pageTile])

    return(
        <div className="col">
                <div class="col-12 col-xxl-8 mb-5">
          <h2 class="small-title">{pageTile}</h2>

          <div class="row g-2">
            
          {posts.map(x => <div class="col-12 col-lg-12">
              <div class="card">
                  <div class="col m-1">
                  <div class="card-body d-flex flex-row pt-0 pb-0 ps-3 pe-0 h-100 align-items-center justify-content-between">
                      <div class="d-flex flex-column">
                        <div class="font-heading">{truncate(x.subject, 200)}</div>
                        <div style={{whiteSpace: "pre-wrap"}}>
                        <div class="text-normal text-muted">{truncate(x.announcement, 500)}</div>
                      </div>
                      </div>
                      
                      <div style={{zIndex: 100}} class="d-flex">
                    <i onClick={() => deleteBtn(x.id)} className="fa-regular fa-times fa-2x text-danger"></i>
                    </div>
                    </div>

                    
                  
                  </div>
                  

              </div>
            </div>
            )}

          </div>
        </div>
        </div>  
    )
}

export default Announcements;