import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";
import moneyFormat from "../../utilities/moneyFormat";

const Coupons = () => {

    const [pageTile, setPageTitle] = useState("Coupons")
    
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [numbers, setNumbers] = useState(null)
    const [country, setCountry] = useState(null)
    const [package_, setPackage] = useState(null)

    const packages = [
      {id: 1, package: "CEO", amount: "3000"},
      {id: 2, package: "Main CEO", amount: "5000"},
      {id: 3, package: "Themainceos CEO", amount: "70000"}
    ]

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        //userProfile()
        setLoading(false)
      }

      const userProfile = async () => {
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            setCountry(y.country)
        }
      }

      const getCoupons = async () => {
        setLoading(true)
        let x = await requestHandler.get('vendor/coupons', true);
        if(x != null && x.status === 1){
            setData(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const [used, setUsed] = useState(0)
      const [unsed, setUnused] = useState(0)
      const [couponsLeft, setCouponsLeft] = useState(0)
      const couponStats = async () => {
        setLoading(true)
        let x = await requestHandler.get('vendor/coupons/stats', true);
        if(x != null && x.status === 1){
          setUsed(x.used)
          setUnused(x.un_used)
          setCouponsLeft(x.coupons)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const createCoupons = async () =>{
        let url = 'vendor/coupon/generate';
        setLoading(true)
        let x = await requestHandler.post(url, {package: package_, numbers: numbers}, false, true)
            if(x != null && x.status === 1){
                toast.success(x.message)
                getCoupons()
                couponStats()
            }
            else if(x != null && x.status === 0){
                toast.error(x.message)
            }
            else{
                toast.error('Something went wrong')
            }
        setLoading(false)
    }
      
    useEffect(() => {
        checkIfLoggedIn()
        couponStats()
        getCoupons()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
             <h2 class="small-title">Coupons</h2>

             <form className="card mt-5 mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6">
                        <div className="mb-3">
                          <select onChange={e => setPackage(e.target.value)} class="form-control" data-width="100%">
                              <option>Select Package</option>
                            {
                              packages.map((x) => {
                              return (<option value={x.package}>{x.package} - ₦{x.amount}</option>)  
                              })
                          }
                          </select>
                        </div>
                        </div>
                      <div className="col-md-6 col-lg-6 col-xl-6">
                      <div className="mb-3">
                        <input value={numbers} onChange={e => setNumbers(e.target.value)} className="form-control" type="number" placeholder="Numbers" />
                      </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-xl-6">
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={createCoupons} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Generate Coupon</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                      </div>
                    </div>
                    
                  </form>


             <div class="row g-2">
             {/*<div class="col-12 col-sm-12 col-lg-12">
                      <div class="card bg-success hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>COUPONS LEFT</span>
                            <i className="fa-regular fa-box text-white"></i>
                          </div>
                          <div class="text-small text-white mb-1">Total</div>
                          <div class="cta-1 text-white">{couponsLeft}</div>
                        </div>
                      </div>
                        </div>*/}
             <div class="col-12 col-sm-6 col-lg-6">
             <div class="card bg-success hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>UN-USED COUPONS</span>
                            <i className="fa-regular fa-box text-white"></i>
                          </div>
                          <div class="text-small text-white mb-1">Total</div>
                          <div class="cta-1 text-white">{unsed}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>USED COUPONS</span>
                            <i className="fa-regular fa-box text-danger"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{used}</div>
                        </div>
                      </div>
                    </div>
             </div>
         


            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

                <table className="table table-striped">
        <thead>
          <tr>
          <th>Date</th>
          <th>Code</th>
          <th>Package</th>
          {/*<th>Used by</th>*/}
          <th>Status</th>
          </tr>
          </thead>
          <tbody>
      {data.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}</td>
          <td>{x.code}</td>
          <td>{x.package}</td>
          {/*<td>{x.username}</td>*/}
          <td>{x.status == 0? <span className="text-success">Available</span> : <span className="text-danger">Used</span>}</td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default Coupons;