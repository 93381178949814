import React, { Component, useEffect, useState } from 'react';

import images from '../../../../../utilities/images';
import urls from '../../../../../utilities/urls';
import SuccessMessage from '../../../../../utilities/successMessage';

import { Link } from 'react-router-dom';
const ElectricityBill = () => {

    const services = [
        {
            id: 8,
            service: "EKEDC TOKEN",
            path: "ekedc",
            subText: "Purchase",
            img: images.ekedc 
        },
        {
            id: 9,
            service: "IKEDC TOKEN",
            path: "ikedc",
            subText: "Purchase",
            img: images.ikedc 
        },
        {
            id: 10,
            service: "PHEDC TOKEN",
            path: "phed",
            subText: "Purchase",
            img: images.phedc 
        },
        {
            id: 11,
            service: "AEDC TOKEN",
            path: "aedc",
            subText: "Purchase",
            img: images.aedc 
        },
        {
            id: 12,
            service: "KEDC TOKEN",
            path: "kedco",
            subText: "Purchase",
            img: images.kedc 
        },
        {
            id: 13,
            service: "KDEDC TOKEN",
            path: "kedc",
            subText: "Purchase",
            img: images.kdedc 
        },
        {
            id: 14,
            service: "IBEDC TOKEN",
            path: "ibedc",
            subText: "Purchase",
            img: images.ibedc 
        },
        {
            id: 15,
            service: "JEDC TOKEN",
            path: "jedc",
            subText: "Purchase",
            img: images.jedc 
        },
        {
            id: 16,
            service: "EEDC TOKEN",
            path: "eedc",
            subText: "Purchase",
            img: images.eedc 
        },
        
    ]
    
    const [userData, setUserData] = useState([]);
    const [wallet, setWallet] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [pendingPackage, setPendingPackage] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('access_token'));
    const [errMsg, setErrMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [pin, setPin] = useState(null);
    const [isLoading, setLoading] = useState(false);

    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +token          
        }
      }

    const getUserDetails = () => {
        setLoading(true)
        
        fetch(urls.apiurl +'vtu/user/user_details/', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {
                    setUserData(json.result)
                    setWallet(json.result.wallet)  
                    if(json.result.pin == null){
                        window.location.replace("/user/create_pin");
                    }
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const checkPendingPackage = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/user/check_pending_package', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {
                    setPendingPackage(json.result)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const getTransactions = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/transaction/10/transactions', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {
                    setTransactions(json.result)
                    //console.log("dataa"+ transactions)
                }
            })
            .catch((error) => console.error("Goterr "+error))
            .finally(() => setLoading(false));
    }

    useEffect(()=>{
       // getUserDetails();
        
        
        checkPendingPackage();
        getTransactions();
//        console.log(token)
    },[])

    return <div class="col">
        <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
          <div class="row">
         
          {successMsg != null? <div class="col-xl-12 col-lg-12">
          <div class="box-body text-center">
              <SuccessMessage message={successMsg}/>
              </div>
              </div>
               : null  }

          
          {services.map(x =>
            <div class="col-6 col-xl-4 col-lg-6">
                <Link to={"/vtu/user/"+x.path+"/pay"}>  
                  <div class="box">
                      <div class="box-body text-center">
                      <div class="d-flex flex-column mb-3 justify-content-around">
                              <div class="b-1"></div>
                              <div>
                                  <img className='rounded-circle' src={x.img} style={style.logo} />
                              </div>
                              <div>
                                  <h6 class="my-0">{x.service}</h6>
                              </div>
                          </div>
                      </div>
                  </div>
                  </Link>
              </div>
              
          )}
              
          </div>	
          
      </section>
    </div>
</div>
<div class="control-sidebar-bg"></div>  
</div>
;

}

export default ElectricityBill;

const style = {
    logo: {width: 50, heiht: 50}
};