
import { useEffect, useState } from "react";
import Loading from "../../utilities/Loading";
import HomeContents from "./homeContents";
import PopularContent from "./popularContent";
import requestHandler from "../../utilities/requestHandler";

const RecentContentsForUsers = () => {
    const [pageTile, setPageTitle] = useState("Recent Contents")
    const [announcement, setAnnouncement] = useState([])
    const [popUp, setPopUp] = useState(false)
    const [lastPopId, setLastPopId] = useState(localStorage.getItem('last_pop_id'))
    const [readHowItWorks, setReadHowItWorks] = useState(localStorage.getItem('readHowItWorks'))

    const checkIfLoggedIn = async () => {
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
      }

    const getInfoModal = async () => {
        try{
            let x = localStorage.getItem('readHowItWorks');
            if(x !== null) setReadHowItWorks(x)
            if(x == null){
                setPopUp(true)                
            }
            else{
                setPopUp(false)           
            }
        }
        catch(e){
            console.log(e)
        }        
    }

    const exitModal = () =>{
        localStorage.setItem('readHowItWorks', "Yes")
        setPopUp(false)
    }

    const closePopUp = () => {
        setPopUp(false)
    }
    useEffect(() => {
    document.title = pageTile
    },[pageTile])
    useEffect (()=>{
        //checkIfLoggedIn()
        getInfoModal()        
    },[announcement])

    const PopUp = () => {
        return(
            <div class="modal fade modal-under-nav modal-search modal-close-out show" id="popUpModal" tabindex="-1" role="dialog" aria-modal="true" style={{display: 'block'}}>
                 <div class="fixed-background"></div>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button type="button" class="btn-close btn btn-icon btn-icon-only btn-foreground" data-bs-dismiss="modal" aria-label="Close" onClick={closePopUp}></button>
          </div>
          <div class="modal-body ps-5 pe-5 pb-0 border-0">
            <h4>How to Share Viral Posts on TheMainceos</h4>
          </div>
          <div class="modal-body border-top justify-content-start ps-5 pe-5 pb-3 pt-3 border-0">
                    <div class="justify-content-start">
                        <div style={{whiteSpace: "pre-wrap"}} class="text-normal">
                        Dear Member,
<div className="mb-2"></div>
Sharing viral posts for TheMainceos is a great way to promote our platform and earn bonuses. 
Here's a quick guide to maximize your efforts:
<div className="mb-2"></div>
<b>1. Create a social media account most especially Facebook and Join Facebook Group Chats:</b> Join relevant Facebook group chats to expand your reach.
   <div className="mb-2"></div>
   <b>2. Copy and Share:</b> Copy the latest viral post from TheMainceos and share it in these group chats. Include your referral link or WhatsApp number for inquiries.
   <div className="mb-2"></div>
   <b>3. Engage Actively:</b> Respond promptly to inquiries and engage with interested individuals to explain the benefits of TheMainceos.
   <div className="mb-2"></div>
   <b>4. Convert to Members:</b> Use your communication skills to convert interested individuals into registered members of TheMainceos.
   <div className="mb-2"></div>
Remember, consistency and engagement are key to success.
<div className="mb-2"></div>
<div>
<iframe width="100%" height="315" src="https://www.youtube.com/embed/qOAoZeASl5c?si=tP30KPaWWNBQSA91" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>

Happy sharing and earning with TheMainceos!
<div className="mb-2"></div>
Click The Button below if you have read and understand how viral share works.
<div className="mt-2">
    <button onClick={exitModal} className="btn btn-primary">I have read and understand!</button>
</div>

                             </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        )
    }

    return (
        <div class="col">
           
            {/*popUp? <PopUp/> : ''*/}
            
            <div className="row">
            <div class="col-md-6 col-xl-8 col-xxl-9 mb-5">  
<div onClick={()=>setPopUp(true)} className="text-primary"> <i className="fa fa-chevron-right"></i> How Viral Share Works</div>
                <HomeContents />

                {popUp? <PopUp/> : ''}
                
            </div>
            <div class="col-md-1 col-xl-4 col-xxl-9 mb-5">
            <PopularContent/>
            </div>
            </div>
            </div>
    )
}

export default RecentContentsForUsers;