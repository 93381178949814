import { useEffect, useState } from "react"
import requestHandler from "../../utilities/requestHandler"
import images from "../../utilities/images"
import dateTimeToDate from "../../utilities/dateTimeToString"
import { toast } from "react-toastify"

const Profile = () => {
    let account_type = localStorage.getItem('account_type')
    const [pageTile, setPageTitle] = useState("Profile")
    const [userData, setUserData] = useState([])

    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [dob, setDob] = useState(null)
    const [gender, setGender] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [country, setCountry] = useState(null)
    const [picture, setPicture] = useState(null)
    const [username, setUsername] = useState(null)
    const [url, setUrl] = useState(null)
    const [isLoading, setLoading] = useState(false)

    async function copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        toast.success('Link copied')
      } catch (e) {
        toast.error('Error copying')
      }
    }

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login/redirect"
      }
    }

    const websiteSettings = async () => {
      let x = await requestHandler.get('settings/get', false);
      if(x != null && x.status === 1){
          let y = x.message;
          setUrl(y.url)
      }
  }

    const userProfile = async () => {
      let x = await requestHandler.get('user/profile', true);
      if(x != null && x.status === 1){
          let y = x.message[0];
          //setUserData(y)
          setEmail(y.email)
          setPhone(y.phone)
          setFirstName(y.first_name)
          setLastName(y.last_name)
          setUsername(y.username)
          setCountry(y.country)
          setPicture(y.picture)
          setDob(y.dob)
          setGender(y.gender)
      }else{
        window.location.href = '/user/profile/update'
      }
    }

    const goToUrl = (url) => {
      return window.location.href = url;
    }
    
    useEffect(() => {
      websiteSettings()
      checkIfLoggedIn()
      userProfile()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
        
        <h2 class="small-title">Profile</h2>
              
              <div class="card mb-5">
                <div class="card-body">
                  <div class="d-flex align-items-center flex-column mb-4">
                    <div class="d-flex align-items-center flex-column">
                      <div class="sw-13 position-relative mb-3">
                      <img src={picture !== null? picture :  images.profileImage} class="img-fluid rounded-xl" alt="thumb" />
                      </div>
                      <div class="h5 mb-0">{firstName} {lastName}</div>
                      <div class="text-muted">{account_type}</div>
                      <div class="text-muted">
                      <i className="fa-regular fa-map-marker me-1" data-cs-size="17"></i>
                        <span class="align-middle">{country}</span>
                      </div>
                    </div>
                  </div>
                  <div class="nav flex-column" role="tablist">
                  <a class="nav-link  px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#overviewTab" role="tab">
                    <i className="fa-regular fa-copy me-2" onClick={()=>copyToClipboard(url+'/auth/'+username+'/register')} data-cs-size="17"></i>
                      <span class="align-middle"> {url}/auth/{username}/register </span>
                    </a>
                    <a class="nav-link  px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#overviewTab" role="tab">
                    <i className="fa-regular fa-envelope me-2" data-cs-size="17"></i>
                      <span class="align-middle">{email}</span>
                    </a>
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#projectsTab" role="tab">
                    <i className="fa-regular fa-phone me-2" data-cs-size="17"></i>
                      <span class="align-middle">{phone}</span>
                    </a>
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
                      <i className="fa-regular fa-calendar me-2" data-cs-size="17"></i>
                      <span class="align-middle">{dateTimeToDate(dob)}</span>
                    </a>
                  </div>
                </div>
              </div>
              
              <div class="col-12 col-md-12 d-flex align-items-start justify-content-end">
                <button onClick={() =>goToUrl('/user/profile/update')} type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto">
                  <i data-cs-icon="edit-square"></i>
                  <span>Edit</span>
                </button>
              </div>
        </div>  
    )
}

export default Profile;