import React, {Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import requestHandler from '../utilities/requestHandler';
import PopularContent from './landing/popularContent';
import truncate from '../utilities/truncateString';
import TimeInWords from '../utilities/timeInWords';
import AdsBanner1 from './ads/banner1';
import images from '../utilities/images';

const Footer = () => {
    
    const [title, setTitle] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [posts, setPosts] = useState([])
    const [search, setSearch] = useState(null)

      const searchContents = async () => {
        if(search !== null && search.length > 3)
    {        
    setLoading(true)
        let x = await requestHandler.get('content/'+search+'/search', true);
        if(x != null && x.status === 1){
            setPosts(x.message)
            //console.log(x.message)
        }
        setLoading(false)
      }
      }

    const websiteSettings = async () => {
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            let y = x.message;
            setTitle(y.title)
        }
    }

    const [loggedIn, setLoggedIn] = useState(false)
    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status == 1){
          let y = x.message;
          setLoggedIn(true)
      }
      else {
        setLoggedIn(false)
      }
  }

    useEffect(()=>{
        websiteSettings()
        checkIfLoggedIn()
       // if(tawkToUrl != null) tawkToChat.TawkToChat(tawkToUrl)
      },[])

      return ( <div className='row'>
          <div class="footer-content">
            <div class="container">
            {/*<AdsBanner1 />*/}

{loggedIn? <>
            <div className="d-flex justify-content-start items-start position-relative">
    <a target="_blank" rel="noreferrer" href="https://t.me/+vaHrEPMSHy9kMTlk" className="position-fixed bottom-0 mb-4">
    <img className="mw-100" width={45} height={45} alt="ads" src={images.telegram} />
    </a>
</div>

<div className="d-flex justify-content-center items-center position-relative">
    <a target="_blank" rel="noreferrer" href="https://chat.whatsapp.com/GOKlq82xKe6ACAsjOPowbx" className="position-fixed bottom-0 mb-4">
    <img className="mw-100" width={45} height={45} alt="ads" src={images.whatsapp} />
    </a>
</div>
</> : ''}

              <div class="row">
                <div class="col-12 col-sm-6">
                  <p class="mb-0 text-muted text-medium">{title}</p>
                </div>
                <div class="col-sm-6 d-none d-sm-block">
                  <ul class="breadcrumb pt-0 pe-0 mb-0 float-end">
                    <li class="breadcrumb-item mb-0 text-medium">
                        <Link to="#" class="btn-link">About</Link>
                        </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      )
}
export default Footer;