import React, { Component, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import NumberToNaira from '../../constants/numberToNaira';
import urls from '../../../../../utilities/urls';
import ErrorMessage from '../../../../../utilities/errorMessage';
import SuccessMessage from '../../../../../utilities/successMessage';

const VerifyTransaction = () => {

   
    const [userData, setUserData] = useState([]);
    const [wallet, setWallet] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('access_token'));
    const [errMsg, setErrMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const {id} = useParams();
    const search = useLocation().search;
    const id_ = new URLSearchParams(search).get('transaction_id');

    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +token          
        }
      }

    const getUserDetails = () => {
        setLoading(true)
        
        fetch(urls.apiurl +'vtu/user/user_details/', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {
                    setUserData(json.result)
                    setWallet(json.result.wallet)
                    
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const verifyTransaction = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/transaction/'+id_+'/verify_transaction', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {
                    setSuccessMsg(json.message)
//                    window.location.replace("/user/dashboard");
                }else{
                    setErrMsg(json.message)
                }
            })
            .catch((error) => console.error("Goterr "+error))
            .finally(() => setLoading(false));
    }

    const getTransactions = () => {
        setLoading(true)
        fetch(urls.apiurl +'vtu/transaction/50/transactions', options)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == 1) {
                    setTransactions(JSON.parse(JSON.stringify(json.result)))
                    //console.log("dataa"+ transactions)
                }
            })
            .catch((error) => console.error("Goterr "+error))
            .finally(() => setLoading(false));
    }


    useEffect(()=>{
        getUserDetails();
        
        getTransactions()
        verifyTransaction();
    },[])

    return <div class="col">
        <div class="content-wrapper">
    <div class="container-full">
      <section class="content">
           <div class="row">
              <div class="col-xl-12 col-12">

                  <div class="col-xl-12 col-12">
                  {successMsg != null? <div class="col-xl-12 col-lg-12">
          <div class="box-body text-center">
              <SuccessMessage message={successMsg}/>
              </div>
              </div>
               : null  }

{errMsg != null? <ErrorMessage message={errMsg}/> : null  }
              </div>
                  
                  <div class="row">					
                  <div class="col-xl-12 col-12">
                  <div class="box">
                      <div class="box-header with-border">
                        <h4 class="box-title">Transactions</h4>
                      </div>
                      <div class="box-body pt-0">
                          <div class="table-responsive">
                            <table class="table mb-0">
                            <tr>
                                <td><a href="javascript:void(0)">Type</a></td>
                                <td><span class="text-muted text-nowrap">
                                     Date</span> </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="mx-5">Amount</span>
                                    </div>
                                </td>
                                <td class="text-end">Status</td>
                              </tr>
                            {transactions.map(tr =>
                              <tr>
                                <td><a href="#">{tr.type}</a></td>
                                <td><span class="text-muted text-nowrap">
                                {tr.created_at} </span> </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="mx-5">{NumberToNaira(tr.amount)}</span>
                                    </div>
                                </td>
                                {tr.status == 0 ? <td class="text-end text-danger">Pending</td> : null}
                                {tr.status == 1 ? <td class="text-end text-success">Successful</td> : null}
                                {tr.status == 2 ? <td class="text-end text-warning">Declined</td> : null}
                              </tr>
                            )}
                             </table>
                          </div>
                      </div>
                  </div>
              </div>				
                    
                          </div>					
              </div>
            
          </div>	
          <div class="row">
             
              

             
          </div>
      </section>
    </div>
</div>
<div class="control-sidebar-bg"></div>  
</div>
;

}

export default VerifyTransaction;

const style = {
    logo: {width: 45, heiht: 45}
};